<template>
  <div>
    <div>
      <!--      fade-->
      <!--      autoplay-->
      <!--      fixed-height="600px"-->
      <VueperSlides
        fade
        autoplay
        fixed-height="100vh"
        :slide-multiple="false"
        :bullets="true"
        :bullets-outside="false"
        :arrows="true"
        :arrows-outside="false"
        :infinite="true"
        :breakpoints="breakpoints"
      >
        <VueperSlide v-for="item in items" :key="item.id" :image="item.mSrc">
          <template #content>
            <div class="overlay"></div>
            <div class="overlayContent">
              <div class="firstLine">{{ item.intro }}</div>
              <div class="secondLine">
                <small>{{ item.title }}</small>
              </div>
              <div class="thirdLine">
                <small>{{ item.mDesc }}</small>
              </div>
            </div>
          </template>
        </VueperSlide>
      </VueperSlides>
    </div>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  name: 'MainSlider',
  props: {
    items: Array,
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      parallaxFixedContent: true,
      breakpoints: {
        992: {
          bullets: false,
          arrows: false,
          visibleSlides: 1,
          touchable: false,
          disableArrowsOnEdges: true,
          fade: true,
          animation: false,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.overlay {
  color: blue;
  position: absolute;
  z-index: 12;
  left: 0;
  width: 100%;
  text-align: center;
  stroke: red;
  stroke-width: 300;
  background: linear-gradient(rgba(0, 0, 0, 0.3), 20%, rgba(0, 0, 0, 0));
}

.overlayContent {
  color: white;
  position: absolute;
  z-index: 12;
  left: 0;
  top: 42%;
  width: 100%;
  height: 40rem;
  text-align: center;
  stroke-width: 300;
  text-shadow: gray 1px 1px;
  font-family: 'Nanum Gothic', sans-serif;
}

@media (max-width: 768px) {
  .slider-item {
    width: 100%;
    height: 40vh;
  }
  .slider-section {
    margin-top: 64px;
  }

  .firstLine {
    font-size: 1rem;
  }
  .secondLine {
    font-size: 1.8rem;
  }
  .thirdLine {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  .slider-item {
    width: 100%;
    height: 60vh;
  }
  .slider-section {
    margin-top: 0px;
  }

  .firstLine {
    font-size: 1.5rem;
  }
  .secondLine {
    font-size: 3rem;
  }
  .thirdLine {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .slider-item {
    width: 100%;
    height: 70vh;
  }
  .slider-section {
    margin-top: 0px;
  }

  .firstLine {
    font-size: 1.5rem;
  }
  .secondLine {
    font-size: 3rem;
  }
  .thirdLine {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .slider-item {
    width: 100%;
    height: 80vh;
  }
  .slider-section {
    margin-top: 0px;
  }

  .firstLine {
    font-size: 1.5rem;
  }
  .secondLine {
    font-size: 4rem;
    padding: 0;
  }
  .thirdLine {
    font-size: 1.5rem;
  }
}

.firstLine {
  font-family: 'Jeju Myeongjo';
}
.secondLine {
  font-family: 'Jeju Myeongjo';
}
.thirdLine {
}
</style>
