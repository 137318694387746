<template>
  <div>
    <VueperSlides
      @ready="slideChanged"
      @slide="slideChanged"
      ref="slides"
      class="no-shadow"
      :autoplay="autoplay"
      :fade="fade"
      :fixed-height="fixedHeight"
      :bullets="bullets"
      :arrows="arrows"
      :visible-slides="slideNumber"
      :touchable="false"
      :disable-arrows-on-edges="false"
      :slide-multiple="true"
      :bullets-outside="true"
      :arrows-outside="true"
      :gap="gap"
      :dragging-distance="70"
      :infinite="true"
      :breakpoints="breakpoints"
    >
      <template #arrow-left>
        <div class="navButton">
          <b-icon icon="chevron-left" class="" font-scale="0.87" />
        </div>
      </template>

      <template #arrow-right>
        <div class="navButton">
          <b-icon icon="chevron-right" class="" font-scale="0.87" />
        </div>
      </template>
      <slot name="slides"></slot>
    </VueperSlides>
    <div :class="{hidden: !isMobile, mobileRow: isMobile}">
      <button type="button" class="mobileButton" @click="prev">
        <b-icon icon="arrow-left" class="" font-scale="1.5" />
      </button>
      <div class="textRow">
        <div>
          <span class="currentText">{{ currentIndex }}</span>
          <span class="slash">/</span>
          <!--<span class="total" data-th-text="${listSize > 3 ? listSize : 3}">3</span>-->
          <span class="totalText">{{ itemTotalCount }}</span>
        </div>
      </div>
      <button type="button" class="mobileButton" @click="next">
        <b-icon icon="arrow-right" class="" font-scale="1.5" />
      </button>
    </div>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    itemTotalCount: Number,
    fixedHeight: String,
    slideNumber: Number,
    breakpoints: Object,
    bullets: {
      type: Boolean,
      default: true,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    fade: {
      type: Boolean,
      default: false,
    },
    gap: Number,
  },
  data() {
    return {
      isMobile: false,
      currentIndex: 0,
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
      this.currentIndex = 1;
    });

    if (this.itemTotalCount <= this.slideNumber) {
      this.touchable = false;
    } else {
      this.touchable = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      console.log('SLIDER-ON-RESIZE', window.innerWidth);
      this.isMobile = window.innerWidth <= 992;
      // this.height = window.innerWidth * 0.5;
    },
    slideChanged(param) {
      console.log(param);
      if (param.currentSlide !== undefined) {
        this.currentIndex = param.currentSlide.index + 1;
      }
    },
    next() {
      if (this.$refs.slides !== undefined) {
        this.$refs.slides.next();
      }
    },
    prev() {
      if (this.$refs.slides !== undefined) {
        this.$refs.slides.previous();
      }
    },
  },
  components: {
    VueperSlides,
  },
};
</script>

<style scoped lang="scss">
h2 {
  padding-top: 50px;
  height: 110px;
  text-align: center;
  color: #222222;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 1.6em !important;
}

#app .navButton {
  width: 40px;
  height: 40px;
  //border: 1px solid #333;
  //border-radius: 50%;
  vertical-align: middle;
  stroke: currentColor;
  text-align: center;
  fill: none;
}

.hidden {
  display: none;
}

.mobileRow {
  display: flex;
  margin-top: -50px;
  justify-content: center;
  padding-bottom: 30px;
}

.mobileButton {
  margin: 10px;
  border-width: 0;
  background-color: white;
  color: gray;
}

.mobileButton :hover {
  color: black;
}

.textRow {
  margin-top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currentText {
  color: #333333;
  font-weight: bold;
  font-size: 1.1em;
}

.slash {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9em;
}

.totalText {
  color: #999999;
  font-size: 1em;
}
</style>
