import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {loginService} from '@/services/login.service';
import {LoginDto, SignUpDto} from '@/services/LoginModels';
import {PinxToken, PMSResLogin, User} from '@/models';
import {HrToken} from '@/model-hr';
import {AxiosResponse} from 'axios';
import AXIOS from '@/services/axios-wrapper';
// 로그인 참고
// https://www.bezkoder.com/vuex-typescript-jwt-auth/

const storedUser = localStorage.getItem('user');
const storeHrToken = sessionStorage.getItem('hrToken');

@Module({namespaced: true})
class UserAuth extends VuexModule {
  public status = storedUser ? {loggedIn: true} : {loggedIn: false};
  public user?: User = storedUser ? JSON.parse(storedUser) : undefined;
  public hrToken?: HrToken = storeHrToken ? JSON.parse(storeHrToken) : undefined;

  @Mutation
  public logout(): void {
    return;
  }

  @Action
  async initLogin() {
    return;
  }
  @Action({rawError: true})
  async getHrToken() {
    const apiPath = '/hrgetToken';
    return await AXIOS.get<any>(apiPath);
  }
  get hrAccessToken(): string | null {
    if (this.hrToken) return this.hrToken!.access_token;
    return null;
  }
  @Mutation
  public updateHrToken(tokenObj?: HrToken): void {
    if (!tokenObj) {
      this.context.commit('logout');
      return;
    }
    this.hrToken = tokenObj;
    sessionStorage.setItem('hrToken', JSON.stringify(this.hrToken));
  }
}

export default UserAuth;
