<template>
  <div class="perfectItem">
    <h5 class="mt-3 text-center">
      <p class="text-white">
        <strong>{{ item.title }}</strong>
      </p>
    </h5>
    <p class="mt- text-center">
      <small class="itemDesc">{{ item.pDesc }}</small>
      <!--      <small class="itemDesc"> {{ item.desc }} </small>-->
    </p>
  </div>
</template>

<script>
export default {
  name: 'PerfectJourneyItem',
  components: {},
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      price: 0,
      isMobile: false,
      filterCategory: 'all',
      visible: false,
    };
  },
};
</script>

<style scoped lang="scss">
@mixin ellipsis($line-cnt, $line-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-cnt; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: normal;
  line-height: $line-height;
  height: ($line-height * $line-cnt); /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
}

.itemDesc {
  @include ellipsis(1, 1.6rem);
  position: relative;
  color: white;
  font-size: 0.8rem !important;
  line-height: 1.6rem;
}

p {
  color: white;
  line-height: 1.2em;
  word-break: normal;
}

.perfectItem {
  position: absolute;
  color: white;
  background-color: transparent;
  left: 50%;
  top: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
}
</style>
