import Vue, {VNode} from 'vue';
import Component from 'vue-class-component';

import moment from 'moment';
import _ from 'lodash';
import CONST from './Const';
import {AxiosInstance} from 'axios';
import 'moment/locale/ko';
import {PMS_YYYYMMDD} from '@/models-hotel';
import {MemberInfo, User} from '@/models';
import {namespace} from 'vuex-class';
const Auth = namespace('Auth');

declare global {
  interface Window {
    opera: any;
  }
}
const formatter = new Intl.NumberFormat('ko-Kr', {
  // style: 'currency',
  // currency: 'USD',
  minimumFractionDigits: 0,
});

export function commaStr(value: string | number | undefined) {
  if (value == null) return '';
  if (_.isNumber(value)) return formatter.format(value);
  const intValue = parseInt(value, 10);
  return formatter.format(intValue);
}

export function phoneFomatter(num: string) {
  if (!num) return '';

  //숫자만 허용
  num = num.replace(/[^0-9]/g, '');
  num = num.substring(0, 11);

  let formatNum = '';
  if (num.length == 11) {
    formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (num.length == 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (num.indexOf('02') == 0) {
      formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }
  return formatNum;
}

export function cardFomatter(num: string) {
  if (!num) return '';

  let formatNum = num;
  if (num.length == 16) {
    formatNum = num.replace(/(.{4})(.{4})(.{4})(.{4})/, '$1-****-****-$4');
  } else if (num.length == 15) {
    formatNum = num.replace(/(.{4})(.{4})(.{4})(.{3})/, '$1-****-****-$4');
  }

  return formatNum;
}

export function noSpaceFomatter(str: string) {
  if (!str) return '';

  const formatStr = str.replace(/ +/g, '');
  return formatStr;
}

@Component({
  // other options
  filters: {
    dt_yyyy_mm_dd_hh_mm_ss(value: string) {
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },

    dt_yyyy_mm_dd_hh_mm(value: string) {
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD HH:mm');
    },
    dt_yyyy_mm_dd(value: string) {
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD');
    },
    dt_yyyy_mm_dot(value: string) {
      if (!value) return '';
      return moment(value).format('YYYY. MM. DD.');
    },
    comma(value: string | number) {
      const v = String(value);
      return commaStr(v);
    },
    phoneFmt(value: string) {
      return phoneFomatter(value);
    },
    cardFmt(value: string) {
      return cardFomatter(value);
    },
  },
})
export default class GeneralUtil extends Vue {
  @Auth.Getter
  public isLoggedIn!: boolean;

  @Auth.Getter
  public loginUser!: User;

  @Auth.Getter
  public selectedMember!: MemberInfo;

  get myMemType(): string {
    if (!this.selectedMember) return '';
    return this.selectedMember.memType;
  }

  readonly BUBIN_PKG_MEMTYPE = '006'; //법인Pkg 고객 타입 (추천인 예약 가능)

  get isMyMemTypeBubin(): boolean {
    if (!this.selectedMember) return false;
    return this.selectedMember.memType === this.BUBIN_PKG_MEMTYPE;
  }

  get isMyMemTypeCorpMember() {
    // 1~7 번 = 법인PKG/ 골프회원
    if (
      this.selectedMember.memType === '001' ||
      this.selectedMember.memType === '002' ||
      this.selectedMember.memType === '003' ||
      this.selectedMember.memType === '004' ||
      this.selectedMember.memType === '005' ||
      this.selectedMember.memType === '006' ||
      this.selectedMember.memType === '007'
    ) {
      return true;
    }
    return false;
  }

  //$bvModal.msgBoxConfirm 의 내용을 html로 넣고 싶을 때 사용
  //참고 : https://github.com/bootstrap-vue/bootstrap-vue/issues/4073
  htmlToVNode(html: any) {
    return [this.$createElement('div', {domProps: {innerHTML: html}})];
  }

  cvtCrToBr(msg: string) {
    msg = msg.replace(/(\n|\\n)/gi, '<br/>'); // PMS에서 \n 이 그대로 전달되는 경우
    return msg;
  }

  cvtPmsMsgSafe(msg: string) {
    return this.htmlToVNode(this.cvtCrToBr(msg));
  }

  showMsgBoxOkPmsErr(msg: string, title?: string) {
    return this.showMsgBoxOk(this.cvtPmsMsgSafe(msg), title);
  }

  async showMsgBoxOk(msg: string | VNode[], title?: string) {
    const option: any = {
      centered: true,
      okVariant: 'primary',
      okTitle: '확인',
    };

    if (title) {
      option.title = title;
    }

    return this.$bvModal.msgBoxOk(msg, option);
  }

  async showMsgBoxConfirm(msg: string | VNode[], title?: string) {
    const option: any = {
      centered: true,
      okVariant: 'primary',
      okTitle: '확인',
      cancelTitle: '취소',
    };

    if (title) {
      option.title = title;
    }

    return this.$bvModal.msgBoxConfirm(msg, {
      centered: true,
      okVariant: 'primary',
      okTitle: '확인',
      cancelTitle: '취소',
    });
  }

  getApiErrorMsg(err: any) {
    // console.log(err);
    let errMsg = err;
    if (err.response && err.response.data) {
      if (err.response.data.apierror && err.response.data.apierror.message) {
        // 정의된 API error일경우 (의도적으로 발생시킨 exception)
        errMsg = err.response.data.apierror.message;
      } else if (err.response.data.error) {
        // 정의되지 않은 에러 (Internal server error 등)
        errMsg = `${err.response.data.error}:${err.response.data.message}, check console...`;
      }
      console.error(err.response.data);
    } else {
      console.error(errMsg);
    }

    // 서버가 동작하지 않거나 에러 자체가 설정되지 않은경우
    if (errMsg == null || typeof errMsg === 'undefined') {
      errMsg = '서버가 동작하지 않는것 같습니다. 연결상태를 확인해주세요.';
    }
    return errMsg;
  }

  showLoadingModal() {
    this.$EventBus.$emit('showLoadingModal');
  }

  hideLoadingModal() {
    this.$EventBus.$emit('hideLoadingModal');
  }

  isMobileAgent() {
    // 참고 : https://stackoverflow.com/a/11381730
    let check = false;
    /* eslint-disable */
    // @ts-ignore
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    /* eslint-enable */
    return check;
  }

  isDesktopView() {
    // bootstrap md 기준에 따라 나눈다.
    // Medium devices (tablets, 768px and up)
    // @media (min-width: 768px) { ... }
    return !this.isMobileAgent();
  }

  date_today_yyyymmdd(d?: any) {
    return moment(d).format('YYYY-MM-DD');
  }

  onItemClick(o: any) {
    console.log(o);
  }
  // 2022-01-01(토)
  dayStringShort(d?: any) {
    return `${moment(d).format('YYYY-MM-DD')}(${this.dayOfWeek_short(d)})`;
  }

  dayStringShortPMS(pmsDayStr: string) {
    const pmsDay = moment(pmsDayStr, PMS_YYYYMMDD);
    return `${moment(pmsDay).format('YYYY-MM-DD')}(${this.dayOfWeek_short(pmsDay)})`;
  }

  momentYYYY_MM_DD_HH_mm(d?: any) {
    return moment(d, 'YYYY-MM-DD HH:mm');
  }

  // 토
  dayOfWeek_short(d?: any) {
    return moment(d).locale('ko').format('dddd').substr(0, 1);
  }

  // 토요일
  dayOfWeek_long(d?: any) {
    return moment(d).locale('ko').format('dddd');
  }

  // 박 수 구하기
  calcDaysBetween(start_yyyymmdd: string, end_yyyymmdd: string) {
    const start = moment(start_yyyymmdd).format(PMS_YYYYMMDD);
    const end = moment(end_yyyymmdd).format(PMS_YYYYMMDD);
    //console.log(start, end);

    return moment(end).diff(start, 'days');
  }

  axios_put_urilist(AXIOS: AxiosInstance, uri: string, list: any) {
    // console.log(list);
    if (list.length === 0) return Promise.resolve();
    return AXIOS.put(uri, list, {
      headers: {
        'Content-Type': 'text/uri-list',
      },
    });
  }

  build_search(scObj: any, prefix: any) {
    let result = '';
    prefix = prefix || '';
    Object.entries(scObj).forEach(([key, value]) => {
      // console.log(key, Array.isArray(value));
      if (Array.isArray(value)) {
        value.forEach(x => {
          if (x) result += `&${prefix}${key}=${x}`;
        });
      } else if (value || value === false) result += `&${prefix}${key}=${value}`;
    });
    return result;
  }

  text_i18n(t: string) {
    const arrs = t.split('||');
    //console.log(arrs, this.$i18n.locale);
    const defaultResult = arrs[0];
    if (arrs.length === 0) return defaultResult;
    else {
      if (this.$i18n.locale === 'ko' && arrs.length > 0) return arrs[0];
      else if (this.$i18n.locale === 'en' && arrs.length > 1) return arrs[1];
      else if (this.$i18n.locale === 'ja' && arrs.length > 2) return arrs[2];
      else return defaultResult;
    }
  }
}
