












import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import boardService, {BoardParam} from '@/services/board.service';
import MY_BID from '@/services/boardIds';
import Popup from '@/pages/Popup/Component/Popup.vue';
import {BoardArticle} from '@/models';
import {Prop} from 'vue-property-decorator';

@Component({
  components: {
    GeneralUtil,
    Popup,
  },
})
export default class PopupPage extends GeneralUtil {
  popupList: BoardArticle[] = [];

  @Prop()
  subtitle1!: string;

  getCookieKey(item: BoardArticle) {
    return `popup|${MY_BID.POPUP}|${item.id}`;
  }

  async created() {
    let p = new BoardParam(MY_BID.POPUP);
    //팝업은 최대 3개 까지만
    p.pageSize = 3;
    p.aProjection = 'BoardArticleDetailWithFiles';
    if (this.subtitle1) p.subtitle1Search = this.subtitle1;

    this.popupList = await boardService.getArticleListObjParam(p);

    // 저장한 쿠키값과 체크하여 isView를 설정함
    this.popupList.forEach(x => {
      const val = this.$cookies.get(this.getCookieKey(x));
      if (val) {
        x.isView = false;
      }
    });
  }

  hide(item: BoardArticle, setCookie: boolean) {
    //console.log(item);
    if (setCookie) this.$cookies.set(this.getCookieKey(item), true);
    item.isView = false;
  }

  get filteredList() {
    //모바일에서는 1개만 표시함
    let result = this.popupList.filter(x => x.isView);
    if (this.isMobileAgent()) return result.slice(0, 1);

    return result;
  }
}
