<template>
  <!--  :image="require(`@/assets/images/main/${item.image}`)"-->
  <div ref="top">
    <img class="itemImage" :src="require(`@/assets/images/main/${item.image}`)" @load="onLoad" />
  </div>
</template>

<script>
export default {
  name: 'RestaurantItem',
  components: {},
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      imageLoaded: false,
    };
  },

  methods: {
    onLoad() {
      this.imageLoaded = true;
      console.log('HEIGHT>>>!!!2-CHILD-ONLOAD', this.$refs.top.clientHeight, this.imageLoaded);
      this.$emit('ImgLoaded');
    },
  },
};
</script>

<style scoped lang="scss">
@mixin ellipsis($line-cnt, $line-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-cnt; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: normal;
  line-height: $line-height;
  height: ($line-height * $line-cnt); /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
}

.itemImage {
  width: 100%;
}
</style>
