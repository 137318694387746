<template>
  <div>
    <div>
      <h2>LOCATION</h2>
      <div class="imageStyle">
        <img :src="require('@/assets/images/main/home-map-location.jpg')" v-show="isLang === 0" />
        <img :src="require('@/assets/images/main/home-map-location_en.jpg')" v-show="isLang === 1" />
        <img :src="require('@/assets/images/main/home-map-location_jp.jpg')" v-show="isLang === 2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Location',
  data() {
    return {
      isLang: 0,
    };
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
        this.categoryItems = this.categoryItems_ko;
      } else if (newVal === 'en') {
        this.isLang = 1;
        this.categoryItems = this.categoryItems_en;
      } else if (newVal === 'ja') {
        this.isLang = 2;
        this.categoryItems = this.categoryItems_jp;
      } else {
        this.isLang = 0;
        this.categoryItems = this.categoryItems_ko;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>

<style scoped lang="scss">
h2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: 8rem;
  text-align: center;
  color: #222222;
  font-family: 'Jeju Myeongjo', sans-serif;
  font-weight: 700;
}

.imageStyle {
  padding-top: 20px;
  padding-bottom: 50px;
  > img {
    width: 100%;
  }
}
</style>
