import {BOARD_ID} from '@/util/BoardConst';

export const BOARD_PODO_ID_PKG = BOARD_ID.BID_PODO_HOTEL_PKG;
export const BOARD_PODO_ID_ROOM = BOARD_ID.BID_PODO_HOTEL_ROOM;
export const BOARD_PODO_ID_PKG_NEW = BOARD_ID.BID_PODO_HOTEL_PKG_NEW;
export const BOARD_PODO_ID_DINING_NEW = BOARD_ID.BID_PODO_RESTAURANT_DETAIL;

export const PODO_URL = 'https://podo.thepinx.co.kr';

export const BOARD_ANNEX_ID_PKG = BOARD_ID.BID_ANNEX_HOTEL_PKG;
export const BOARD_ANNEX_ID_ROOM = BOARD_ID.BID_ANNEX_HOTEL_ROOM;
export const BOARD_ANNEX_ID_PKG_NEW = BOARD_ID.BID_ANNEX_HOTEL_PKG_NEW;
export const BOARD_ANNEX_ID_DINING_NEW = BOARD_ID.BID_ANNEX_RESTAURANT_DETAIL;

export const ANNEX_URL = 'https://theannex.thepinx.co.kr';
