<template>
  <div ref="top">
    <div class="card itemContainer">
      <img
        v-if="item.thumbnailUrl && item.thumbnailUrl !== ''"
        class="card-img-top"
        :src="`${item.thumbnailUrl}`"
        alt="Card image cap"
        style="padding: 10px"
        @load="onLoaded"
      />
      <img
        v-if="!item.thumbnailUrl || item.thumbnailUrl === ''"
        class="card-img-top"
        :src="`${item.attachFiles[0].urlPath}${item.attachFiles[0].fileName}`"
        alt="Card image cap"
        style="padding: 10px"
        @load="onLoaded"
      />
      <div class="card-body">
        <h5 class="card-title">{{ item.title }}</h5>
        <div class="lineStyle"><hr /></div>
        <p class="card-text2">{{ item.subtitle3 }}</p>
      </div>

      <div class="card-footer">
        <b-link :href="`${item._linkurl}`">
          <div class="moreText">More <arrow-right-icon class="fea icon-sm" /></div>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import {ArrowRightIcon} from 'vue-feather-icons';

export default {
  name: 'SpecialOffersItem',
  components: {ArrowRightIcon},
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      imageLoaded: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log('HEIGHT>>>!!!0-CHILD', this.$refs.top.clientHeight, this.imageLoaded);
    });
  },
  methods: {
    onLoaded() {
      this.imageLoaded = true;
      console.log('HEIGHT>>>!!!0-CHILD-ONLOAD', this.$refs.top.clientHeight, this.imageLoaded);
      this.$emit('ImgLoaded');
    },
  },
};
</script>

<style scoped lang="scss">
td {
  vertical-align: middle;
}
.top {
}
.mainTitle {
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -0.1rem;
}
.mainTitleLine {
  width: 10%;
  > hr {
  }
}
.mainIntro {
  > span {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0px;
  }
}
.buttonArea {
  width: 100%;
  display: flex;
  justify-content: right;
  .resserve-button {
    width: 7rem;
    height: 2.5rem;
    text-align: center;
    letter-spacing: -0.05rem;
    font-size: 0.9rem;
    line-height: 2rem;
    background-color: #524a3d;
    color: #fff;
    border: none;
  }
  .prev-button {
    justify-content: center;
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: white;
    color: #000;
    border: 1px solid black;
  }
}
.subContainer {
  width: 100%;
}
.mainInfo {
  padding: 1rem;
  width: 100%;
  height: 100%;
  > div {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
.serviceArea {
  padding: 1rem;
  > span {
    font-size: 0.8rem;
  }
}
.subTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.subDetail {
  font-size: 0.9rem;
  padding-left: 1rem;
}
.subTable {
  > thead {
    background-color: #eaeaea;

    > tr > td {
      font-weight: 600;
    }
  }
  > tbody {
    > tr > td {
      font-size: 0.9rem;
    }
  }
}
.rowSpace {
  width: 100%;
  height: 2rem;
  > hr {
  }
}

.card-title {
  font-weight: 600;
  letter-spacing: -0.05rem;
}
.card-body {
  height: 10rem;
  padding: 1rem;
  > p {
    font-size: 0.9rem;
    word-break: keep-all;
    letter-spacing: -0.03rem;
  }
}
.moreText {
  color: #937348;
}

.card-footer {
  background-color: white;
  padding: 1rem;
}
</style>
