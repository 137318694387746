// numeric enum 이라 순서 지켜줘야함.
export enum BOARD_ID {
  BID_NONE,
  BID_SHARED_PHOTO, //전사이트 공용 포토뷰 게시판
  BID_MAIN_NOICE, //메인 - 공지사항
  BID_MAIN_FAQ, //메인 - FaQ (안쓸수도 있음)
  BID_GOLF_MAIN_SLIDER, //골프 - 메인슬라이더
  BID_GOLF_NOTICE, //골프 - 공지사항
  BID_GOLF_PHOTO, //골프 - 포토뷰
  BID_GOLF_NEWSROOM, //골프 - 뉴스룸
  BID_GOLF_SPECIAL_OFFER, //골프 - 스페셜 오퍼
  BID_GOLF_RESTAURANT, //골프 - 레스토랑
  BID_PODO_MAIN_SLIDER, //포도 - 메인슬라이더
  BID_PODO_NOTICE, //포도 - 공지사항
  BID_PODO_PHOTO, //포도 - 포토뷰
  BID_PODO_NEWSROOM, //포도 - 뉴스룸
  BID_PODO_GALLERY, //포도 - 갤러리소이
  BID_PODO_SPECIAL_OFFER, //포도 - 스페셜 오퍼
  BID_PODO_RESTAURANT, //포도 - 레스토랑
  BID_PODO_CUSTOMER_REVIEW, //포도 - 고객리뷰 (안쓸수도 있음)
  BID_ANNEX_MAIN_SLIDER, //디아넥스 - 메인슬라이더
  BID_ANNEX_NOTICE, //디아넥스 - 공지사항
  BID_ANNEX_PHOTO, //디아넥스 - 포토뷰
  BID_ANNEX_NEWSROOM, //디아넥스 - 뉴스룸
  BID_ANNEX_SPECIAL_OFFER, //디아넥스 - 스페셜오퍼
  BID_ANNEX_RESTAURANT, //디아넥스 - 레스토랑
  BID_ANNEX_CUSTOMER_REVIEW, //디아넥스 - 고객리뷰 (안쓸수도 있음)
  BID_PODO_HOTEL_PKG, //포도 - 상품관리
  BID_PODO_HOTEL_ROOM, //포도 - 객실관리
  BID_ANNEX_HOTEL_PKG, //디아넥스 - 상품관리
  BID_ANNEX_HOTEL_ROOM, //디아넥스 - 객실관리
  BID_GOLF_POPUP, //골프 - 팝업
  BID_PODO_POPUP, //포도- 팝업
  BID_ANNEX_POPUP, //디아넥스 - 팝업
  BID_MAIN_POPUP, //디아넥스 - 팝업
  BID_PODO_HOTEL_PKG_NEW, //포도 - 상품관리 템플릿형
  BID_ANNEX_HOTEL_PKG_NEW, //디아넥스 - 상품관리 템플릿형
  BID_PODO_HOTEL_ROOM_NEW, //포도 - 객실관리 템플릿형
  BID_ANNEX_HOTEL_ROOM_NEW, //디아넥스 - 객실관리 템플릿형
  BID_GOLF_RESTAURANT_DETAIL, //
  BID_PODO_RESTAURANT_DETAIL, //
  BID_ANNEX_RESTAURANT_DETAIL, //
}

// BOARD = title, memo(본문), 이미지첨부, 파일첨부
//   :뉴스룸, 공지사항
// TITLE_PHOTO =  title, subtitle1, subtitle2, 이미지 첨부, link
//   :메인슬라이더, 레스토랑, 레스토랑
// TITLE_PHOTO_TIME =  title, subtitle1, subtitle2, 이미지 첨부, link, time
//   :레스토랑, 스페셜오퍼
// PHOTO_ONLY = title, subtitle, memo 상관 없이 첨부 이미지 로만 사용하는 것
//   :포토뷰

export type ARTICLE_PROJETION =
  | 'BoardArticleSummary'
  | 'BoardArticleDetail'
  | 'BoardArticleDetailWithFiles'
  | 'BoardArticleSummaryWithMemo';

export type SORT_ASC_DESC = 'desc' | 'asc';

export type SORT_FIELD = 'id' | 'sortIdx';
