

















import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {Prop, Watch} from 'vue-property-decorator';
import {BoardArticle} from '@/models';

@Component({
  components: {
    GeneralUtil,
  },
})
export default class Popup extends GeneralUtil {
  @Prop()
  value!: BoardArticle;
  checkBoxValue = false;

  @Watch('value')
  valueChanged() {
    //console.log('value:' + this.value.isView);
    // WARN : 팝업이 여러개일 때 이전 데이터 영향받음, 강제 초기화 설정
    this.checkBoxValue = false;
  }
  mounted() {
    //console.log('mounted:' + this.value.isView);
  }

  hide(setCookie: boolean) {
    //console.log('hide:' + checked);
    this.$emit('hide', this.value, setCookie);
  }

  goToLink() {
    if (this.value.link) window.location.href = this.value.link;
  }

  get isImageOnly() {
    return this.value.subtitle2 === '' || Number(this.value.subtitle2) === 0;
  }
  get isHtml() {
    return Number(this.value.subtitle2) === 1;
  }
}
