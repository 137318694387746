/* ====================================================================== */
/* Util
/* ====================================================================== */
import Constants from '../util/Const';
const AlertMessage = require('@/util/AlertMessage.json');
import axios from 'axios';
import _Vue from 'vue';
import EventBus from './EventBus';

const defaultPlugin = {
  install(Vue: typeof _Vue) {
    /* ====================================================================== */
    /* Axois
    /* ====================================================================== */
    Vue.prototype.$http = axios;

    /* ====================================================================== */
    /* Util
    /* ====================================================================== */
    Vue.prototype.$Constants = Constants;
    Vue.prototype.$AlertMessage = AlertMessage;
    Vue.prototype.$EventBus = EventBus;
    //console.log('DefaultPlugin installed');
    // Vue.prototype.$Cookie = Cookie

    /* ====================================================================== */
    /* MainPage
    /* ====================================================================== */
    //Vue.prototype.$OrderApi = OrderApi;
  },
};

export default defaultPlugin;
