<template>
  <div>
    <div>
      <h2>OTHER SITE</h2>
      <div Class="siteLinks mb-5">
        <div class="container d-flex justify-content-center">
          <a class="siteItem" href="https://www.walkerhill.com/grandwalkerhillseoul/" target="_blank">
            <img :src="require('@/assets/images/main/other-logo-grand.png')" />
          </a>
          <a class="siteItem" href="https://www.walkerhill.com/vistawalkerhillseoul/" target="_blank">
            <img :src="require('@/assets/images/main/other-logo-vista.png')" />
          </a>
          <a class="siteItem" href="https://www.luceville.com/kor/index.do" target="_blank">
            <img :src="require('@/assets/images/main/other-logo-luceville.png')" />
          </a>
          <a class="siteItem" href="https://www.podomuseum.com/" target="_blank">
            <img :src="require('@/assets/images/main/other-logo-podomuseum.png')" />
          </a>
        </div>
        <!--        <div class="textLinks">-->
        <!--          <a href="#"><span>회사소개</span></a>-->
        <!--          <a href="#"><span>인재채용</span></a>-->
        <!--          <a href="#"><span>윤리경영</span></a>-->
        <!--          <a href="#"><span>개인정보 처리 방침</span></a>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtherSite',
  methods: {},
};
</script>

<style scoped lang="scss">
//
a {
  background: transparent;
  text-decoration: none;
  color: inherit;
}

h2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: 8rem;
  text-align: center;
  color: #222222;
  font-family: 'Jeju Myeongjo', sans-serif;
  font-weight: 700;
}

.textLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;

  span {
    padding: 2rem;
  }
}
.siteLinks {
  //display: flex;
  background-color: #f3f3f3;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .container {
    max-width: 540px;
  }
  .siteItem {
    width: 100%;
    padding: 1rem;
    align-self: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 80%;
  }
  .siteItem {
    width: 100%;
    max-width: 200px;
    padding: 2rem;
    align-self: center;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
