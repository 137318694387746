import AXIOS from './axios-wrapper';
import {ARTICLE_PROJETION, BOARD_ID, SORT_ASC_DESC, SORT_FIELD} from '@/util/BoardConst';
import {BoardArticle, BoardArticleResponse, BoardInfo} from '@/models';

export class BoardParam {
  bid!: BOARD_ID;
  pageNumber = 1;
  pageSize = 10;
  aProjection: ARTICLE_PROJETION = 'BoardArticleDetailWithFiles';
  sortField: SORT_FIELD = 'id';
  sortAscDesc: SORT_ASC_DESC = 'desc';
  isView = true;
  subtitle1Search = '';

  constructor(bid: BOARD_ID) {
    this.bid = bid;
  }
}

class BoardService {
  constructor() {
    console.log('creating new instance of BoardService');
  }

  // BOARD API

  async getBoardInfo(bid: BOARD_ID): Promise<BoardInfo> {
    const response = await AXIOS.get<BoardInfo>(`/BoardInfoRepo/${bid}`);
    return response.data;
  }

  async getArticleList(
    bid: BOARD_ID,
    pageNumber = 1,
    pageSize = 10,
    aProjection: ARTICLE_PROJETION = 'BoardArticleSummary',
    sortField: SORT_FIELD = 'id',
    sortAscDesc: SORT_ASC_DESC = 'desc',
    isView = true,
    subtitle1Search = '',
  ): Promise<BoardArticleResponse> {
    let req_uri = `/BoardArticleRepo?projection=${aProjection}&boardInfo.id=${bid}&isView=${isView}&page=${
      pageNumber - 1
    }&size=${pageSize}&sort=${sortField},${sortAscDesc}`;
    if (subtitle1Search) {
      req_uri += `&subtitle1=${subtitle1Search}`;
    }

    const response = await AXIOS.get<BoardArticleResponse>(req_uri);
    return response.data;
  }

  async getArticleListWithMemo(
    bid: BOARD_ID,
    pageNumber = 1,
    pageSize = 10,
    aProjection: ARTICLE_PROJETION = 'BoardArticleSummaryWithMemo',
    sortField: SORT_FIELD = 'id',
    sortAscDesc: SORT_ASC_DESC = 'desc',
    isView = true,
    subtitle1Search = '',
  ): Promise<BoardArticleResponse> {
    let req_uri = `/BoardArticleRepo?projection=${aProjection}&boardInfo.id=${bid}&isView=${isView}&page=${
      pageNumber - 1
    }&size=${pageSize}&sort=${sortField},${sortAscDesc}`;
    if (subtitle1Search) {
      req_uri += `&subtitle1=${subtitle1Search}`;
    }

    const response = await AXIOS.get<BoardArticleResponse>(req_uri);
    return response.data;
  }

  async getArticleListObjParam(p: BoardParam): Promise<BoardArticle[]> {
    const list = await this.getArticleList(
      p.bid,
      p.pageNumber,
      p.pageSize,
      p.aProjection,
      p.sortField,
      p.sortAscDesc,
      p.isView,
      p.subtitle1Search,
    );
    return list._embedded.boardArticles.map(x => new BoardArticle(x));
  }

  async getArticleListObjParamFirst(p: BoardParam): Promise<BoardArticle | null> {
    const result = await this.getArticleList(
      p.bid,
      p.pageNumber,
      p.pageSize,
      p.aProjection,
      p.sortField,
      p.sortAscDesc,
      p.isView,
      p.subtitle1Search,
    );

    if (result._embedded && result._embedded.boardArticles && result._embedded.boardArticles.length > 0)
      return new BoardArticle(result._embedded.boardArticles[0]);

    return null;
  }

  async getArticleById(
    bid: BOARD_ID,
    aProjection: ARTICLE_PROJETION = 'BoardArticleSummary',
    id: number,
  ): Promise<BoardArticleResponse> {
    const req_uri = `/BoardArticleRepo?projection=${aProjection}&boardInfo.id=${bid}&id=${id}`;
    const response = await AXIOS.get<BoardArticleResponse>(req_uri);
    return response.data;
  }

  async getArticleDetails(aid: number): Promise<BoardArticle> {
    const response = await AXIOS.get<BoardArticle>(`/BoardArticleRepo/${aid}?projection=BoardArticleDetail`);

    return response.data;
  }
}

// Export a singleton instance in the global namespace
const boardService = new BoardService();

export default boardService;
