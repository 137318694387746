<template>
  <div>
    <PopupPage v-show="isLang === 0" subtitle1="1" />
    <div class="mainNav">
      <main-slider :items="categoryItems"></main-slider>
    </div>
    <PerfectJourney :items="categoryItems" />
    <SpecialOffers v-show="isLang === 0" />
    <Restaurant :items="restaurantItems" v-show="isLang === 0" />
    <Location />
    <OtherSite />
  </div>
</template>

<script>
import MainSlider from './Components/MainSlider';
import PerfectJourney from '@/pages/MainPage/Components/PerfectJourney';
import SpecialOffers from '@/pages/MainPage/Components/SpecialOffers';
import Restaurant from '@/pages/MainPage/Components/Restaurant';
import Location from '@/pages/MainPage/Components/Location';
import OtherSite from '@/pages/MainPage/Components/OtherSite';
import PopupPage from '@/pages/Popup/PopupPage.vue';

export default {
  name: 'MainPage',
  components: {
    MainSlider,
    PerfectJourney,
    SpecialOffers,
    Restaurant,
    Location,
    OtherSite,
    PopupPage,
  },
  data() {
    return {
      categoryItems: null,
      categoryItems_ko: [
        {
          id: 0,
          intro: 'Pride of World Class',
          title: 'PINX GOLF CLUB',
          mDesc: '신이 만든 천혜의 자연에 최고의 설계자가 서명한 명작',
          pDesc: '세계 100대 골프클럽',
          mSrc: require('@/assets/images/main/home-visual-02.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-01.jpg'),
        },
        {
          id: 1,
          intro: 'Stay in Extraordinary',
          title: 'PODO HOTEL',
          mDesc: '건축·예술·온천, 특별함에 머물다',
          pDesc: '건축 그 자체가 작품',
          mSrc: require('@/assets/images/main/home-visual-01.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-02.jpg'),
        },
        {
          id: 2,
          intro: 'Enjoy All Day All Night',
          title: 'THE ANNEX',
          mDesc: '아름다운 제주 남도의 풍광이 파노라마처럼 펼쳐지는 곳',
          pDesc: '간결한 디자인에 담은 제주의 감성',
          mSrc: require('@/assets/images/main/home-visual-03.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-03.jpg'),
        },
      ],
      categoryItems_en: [
        {
          id: 0,
          intro: 'Pride of World Class',
          title: 'PINX GOLF CLUB',
          mDesc: 'Masterpiece signed by a renowned designer in nature that God created',
          pDesc: 'Top 100 golf club in the world',
          mSrc: require('@/assets/images/main/home-visual-02.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-01.jpg'),
        },
        {
          id: 1,
          intro: 'Stay in Extraordinary',
          title: 'PODO HOTEL',
          mDesc: 'Stay inside architecture, art, hot springs, and specialness',
          pDesc: 'Architecture itself is an art',
          mSrc: require('@/assets/images/main/home-visual-01.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-02.jpg'),
        },
        {
          id: 2,
          intro: 'Enjoy All Day All Night',
          title: 'THE ANNEX',
          mDesc: 'The place with a panoramic view of beautiful southern region of Jeju spreads',
          pDesc: 'Sensibility of Jeju contained in a simple design',
          mSrc: require('@/assets/images/main/home-visual-03.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-03.jpg'),
        },
      ],
      categoryItems_jp: [
        {
          id: 0,
          intro: 'Pride of World Class',
          title: 'PINX GOLF CLUB',
          mDesc: '神が創造した天恵の自然に最高の設計者がサインした名作',
          pDesc: '世界トップ100コース',
          mSrc: require('@/assets/images/main/home-visual-02.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-01.jpg'),
        },
        {
          id: 1,
          intro: 'Stay in Extraordinary',
          title: 'PODO HOTEL',
          mDesc: '建築、芸術、温泉、特別さの中にたたずむ',
          pDesc: '建築, それ自体が芸術',
          mSrc: require('@/assets/images/main/home-visual-01.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-02.jpg'),
        },
        {
          id: 2,
          intro: 'Enjoy All Day All Night',
          title: 'THE ANNEX',
          mDesc: '美しい済州南島の景色がパノラマのように広がる所',
          pDesc: 'シンプルなデザインに込められた済州の感性',
          mSrc: require('@/assets/images/main/home-visual-03.jpg'),
          pSrc: require('@/assets/images/main/home-world-best-banner-03.jpg'),
        },
      ],
      restaurantItems: [
        {
          id: 0,
          title: 'PINX GOLF CLUB',
          url: '/',
          image: 'home-restaurant-01.jpg',
        },
        {
          id: 1,
          title: 'PODO HOTEL',
          desc: '/',
          image: 'home-restaurant-02.jpg',
        },
        {
          id: 2,
          title: 'THE ANNEX',
          desc: '/',
          image: 'home-restaurant-03.jpg',
        },
      ],
      isLang: 0,
    };
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
        this.categoryItems = this.categoryItems_ko;
      } else if (newVal === 'en') {
        this.isLang = 1;
        this.categoryItems = this.categoryItems_en;
      } else if (newVal === 'ja') {
        this.isLang = 2;
        this.categoryItems = this.categoryItems_jp;
      } else {
        this.isLang = 0;
        this.categoryItems = this.categoryItems_ko;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>

<style scoped lang="scss"></style>
