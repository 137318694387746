import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

interface PageName {
  name: string;
}

@Module({namespaced: true})
class Display extends VuexModule {
  // 페이지 이름
  private pageNameVal = '';

  @Mutation
  public setPageName(newName: PageName) {
    this.pageNameVal = newName.name;
  }

  @Action
  changeName(newNameObj: PageName) {
    this.context.commit('setPageName', newNameObj);
  }

  get pageName(): string {
    return this.pageNameVal;
  }
}

export default Display;
