<template>
  <div id="app">
    <b-modal id="modal-loading" hide-header hide-footer centered @hide="preventHideLoading">
      <div class="d-block text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <div class="mt-3" style="font-size: 16px">잠시만 기다려 주세요.</div>
      </div>
    </b-modal>
    <router-view />
  </div>
</template>

<script>
import axios from 'axios';
import Methods from '@/util/Methods';
import GeneralUtil from '@/util/GeneralUtil';
import {BvMsgBoxData} from 'bootstrap-vue/src/components/modal';

export default {
  name: 'app',
  mixins: [GeneralUtil],
  data() {
    return {
      showLoadingModalRequested: false,
    };
  },

  created() {
    // 페이지 상단에 버전정보 표시
    axios({url: '/api/version'}).then(response => {
      const vInfo = response.data;
      //if (vInfo.gitBranch === 'develop') {  // jenkins 에서 항상 HEAD로 나와서 그냥 마지막 태그로 판단.
      // 마지막 태그가 v이면 버전정보를 commit 개수로 표시한다.
      if (vInfo.closestTagName === 'v') {
        const buildVersion = `${vInfo.closestTagName}-${vInfo.closestTagCommitCount}`;
        console.log(vInfo.commitId);
        document.title = `${document.title}-${buildVersion}`;
      }
    });

    this.$EventBus.$on('showLoadingModal', this.onShowLoadingModal);
    this.$EventBus.$on('hideLoadingModal', this.onHideLoadingModal);

    this.$EventBus.$on('showMsgBoxOk', (msg, title, resolve, reject) => {
      //msg 에서 \n이나 \\n이 발견될 경우 자동으로 Br(VNode)로 치환 한다.
      if (msg.match(/(\n|\\n)/gi)) {
        msg = this.cvtPmsMsgSafe(msg);
        console.log(msg);
      }
      this.showMsgBoxOk(msg)
        .then(res => {
          //console.log('this.$EventBus.$on resolved');
          resolve(res);
        })
        .catch(err => reject(err));
    });

    this.$EventBus.$on('showMsgBoxConfirm', (msg, title, resolve, reject) => {
      //msg 에서 \n이 발견될 경우 자동으로 Br(VNode)로 치환 한다.
      if (msg.match(/(\n|\\n)/gi)) {
        msg = this.cvtPmsMsgSafe(msg);
      }
      this.showMsgBoxConfirm(msg)
        .then(res => {
          //console.log('this.$EventBus.$on resolved');
          resolve(res);
        })
        .catch(err => reject(err));
    });

    // test 코드
    // let routerParam = {name: 'MyPage', params: {reservCategory: '포도호텔'}};
    // this.$router.push(routerParam);
  },
  beforeDestroy() {
    this.$EventBus.$off('showMsgBoxOk');
    this.$EventBus.$off('showMsgBoxConfirm');
    this.$EventBus.$off('showLoadingModal', this.onShowLoadingModal);
    this.$EventBus.$off('hideLoadingModal', this.onHideLoadingModal);

    this.$root.$off('bv::modal::show', this.modalHandler);
  },
  mounted() {
    //window.mobileCheck = Methods.isMobile();
    // 로딩 모달 다이얼로그 처리
    // WARN: show() 하고 hide() 바로하면 모달이 닫히지 않는 문제 발생하여 이곳에서 중앙관리한다.
    this.$root.$on('bv::modal::show', this.modalHandler);
  },

  methods: {
    modalHandler(bvEvent, modalId) {
      // console.log('Modal is about to be shown', bvEvent, modalId);
      // flag 가 설정되지 않았다면 modal 창 show 하지 않는다.
      if (modalId !== 'modal-loading') return;
      if (this.showLoadingModalRequested === false) bvEvent.preventDefault();
    },
    onShowLoadingModal() {
      this.showLoadingModalRequested = true;
      // 모달을 의도적으로 다음 tick 에서 열리도록 처리함. hide 가 빨리 호출될 때 이벤트핸들러에서 캐치되도록.
      this.$nextTick(() => this.$bvModal.show('modal-loading'));
    },
    onHideLoadingModal() {
      this.showLoadingModalRequested = false;
      this.$bvModal.hide('modal-loading');
    },
    preventHideLoading(bvModalEvt) {
      // Prevent modal from closing
      // 사용자가 빈공간을 눌러서 닫히는것은 방지한다.
      if (bvModalEvt.trigger === 'backdrop') bvModalEvt.preventDefault();
    },
  },
};
</script>

<style>
@import './assets/css/style.css';
@import './assets/css/colors/default.css';
@import './assets/css/style_hr.scss';
</style>

<style lang="scss">
//@import url(//fonts.googleapis.com/earlyaccess/hanna.css);
//@font-face {
//  font-family: 'Hanna', sans-serif;
//}
@import url('https://fonts.googleapis.com/earlyaccess/jejumyeongjo.css');
@import url('https://fonts.googleapis.com/earlyaccess/jejuhallasan.css');
@import url('https://fonts.googleapis.com/earlyaccess/hanna.css');
//@font-face {
//  font-family: 'Jeju Myeongjo', serif;
//  src: url('https://fonts.googleapis.com/earlyaccess/jejumyeongjo.css');
//}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot);
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot?#iefix)
      format('embedded-opentype'),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff2) format('woff2'),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff) format('woff'),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nanum Myeongjo';
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/Myeongjo.eot);
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/Myeongjo.eot?#iefix)
      format('embedded-opentype'),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/Myeongjo.woff2) format('woff2'),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/Myeongjo.woff) format('woff'),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/Myeongjo.ttf) format('truetype');
}
</style>
