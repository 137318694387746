import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import MainPage from '../pages/MainPage/MainPage.vue';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import store from '../store';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

//!!! 주의 !!!
// "/"를 제외한 모든 Vue 라우터 경로 시작은 /p/ 로 시작해야 브라우저에서 URL 직접 입력시 알맞게 동작하게 됨
// 다른 경로 또한 frontend 에서 처리되게 하고 싶으면 Spring쪽 코드 UiController.java 에서 포워딩 처리 추가 필요함!
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    component: () => import(/* webpackChunkName: "" */ '../Index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: MainPage,
      },
      {
        path: '/p/HrAbout',
        name: 'HrAbout',
        component: () => import('../pages/Hr/About.vue'),
      },
      {
        path: '/p/HrNotice',
        name: 'HrNotice',
        component: () => import('../pages/Hr/HrNotice.vue'),
      },
      {
        path: '/p/HrNoticeResult',
        name: 'HrNoticeResult',
        component: () => import('../pages/Hr/HrNoticeResult.vue'),
      },
      {
        path: '/p/HrNoticeRead/:boardArticleId',
        name: 'HrNoticeRead',
        component: () => import('../pages/Hr/HrNoticePageRead.vue'),
      },
      {
        path: '/p/HrRequest/:hrRequestId',
        name: 'HrRequest',
        component: () => import('../pages/Hr/HrRequest.vue'),
      },
      {
        path: '/p/HrRequestResult/:hrRequestId',
        name: 'HrRequestResult',
        component: () => import('../pages/Hr/HrRequestResult.vue'),
      },
      {
        path: '/p/HrDbReg',
        name: 'HrDbReg',
        component: () => import('../pages/Hr/HrDbReg.vue'),
      },
      {
        path: '/p/HrFaq',
        name: 'HrFaq',
        component: () => import('../pages/Hr/HrFaq.vue'),
      },
      {
        path: '/p/HrWant',
        name: 'HrWant',
        component: () => import('../pages/Hr/HrWant.vue'),
      },
      {
        path: '/p/HrWalfare',
        name: 'HrWalfare',
        component: () => import('../pages/Hr/HrWalfare.vue'),
      },
      {
        path: '/p/HrPolicy',
        name: 'HrPolicy',
        component: () => import('../pages/Hr/HrPolicy.vue'),
      },
      {
        path: '/p/Notice',
        name: 'Notice',
        component: () => import('../pages/About/NoticePage.vue'),
      },
      {
        path: '/p/NoticeRead/:boardArticleId',
        name: 'NoticeRead',
        component: () => import('../pages/About/NoticePageRead.vue'),
      },
      // {
      //   path: '/p/Pleisure',
      //   name: 'Pleisure',
      //   component: () => import('../pages/Pleisure/Pleisure.vue'),
      // },
      {
        path: '/p/Reservpleisure',
        name: 'Reservpleisure',
        component: () => import('../pages/Pleisure/Components/reservpleisure.vue'),
      },
      {
        path: '/p/Luxuryyacht',
        name: 'Luxuryyacht',
        component: () => import('../pages/Pleisure/Components/luxuryyacht.vue'),
      },
      /*{
        path: '/p/yachtandsnorkeling',
        name: 'yachtandsnorkeling',
        component: () => import('../pages/Pleisure/Components/yachtandsnorkeling.vue'),
      },*/
      /*기간 종료로 인한 프로그램 삭제 및 링크 삭제*/
      {
        path: '/p/grbbqandspa',
        name: 'grbbqandspa',
        component: () => import('../pages/Pleisure/Components/grbbqandspa.vue'),
      },
      {
        path: '/p/Challengergolf',
        name: 'Challengergolf',
        component: () => import('../pages/Pleisure/Components/challengergolf.vue'),
      },
      {
        path: '/p/Nobleriding',
        name: 'Nobleriding',
        component: () => import('../pages/Pleisure/Components/nobleriding.vue'),
      },
      {
        path: '/p/Jejufisherman',
        name: 'Jejufisherman',
        component: () => import('../pages/Pleisure/Components/jejufisherman.vue'),
      },
      {
        path: '/p/Happytogether',
        name: 'Happytogether',
        component: () => import('../pages/Pleisure/Components/happytogether.vue'),
      },
      {
        path: '/p/Forestorytellingtour',
        name: 'Forestorytellingtour',
        component: () => import('../pages/Pleisure/Components/forestoytellingtour.vue'),
      },
      {
        path: '/p/Biotopia',
        name: 'Biotopia',
        component: () => import('../pages/Biotopia/Biotopia.vue'),
      },
      {
        path: '/p/Introducepinx',
        name: 'Introducepinx',
        component: () => import('../pages/Footerlink/Introducepinx.vue'),
      },
      {
        path: '/p/Privacyinfo',
        name: 'Privacyinfo',
        component: () => import('../pages/Footerlink/Components/Privacyinfo.vue'),
      },
      {
        path: '/p/TOSgolfclub',
        name: 'TOSgolfclub',
        component: () => import('../pages/Footerlink/Components/TOSgolfclub.vue'),
      },
      {
        path: '/p/TOSpodohotel',
        name: 'TOSpodohotel',
        component: () => import('../pages/Footerlink/Components/TOSpodohotel.vue'),
      },
      {
        path: '/p/TOSannex',
        name: 'TOSannex',
        component: () => import('../pages/Footerlink/Components/TOSannex.vue'),
      },
      {
        path: '/p/Codeofethics',
        name: 'Codeofethics',
        component: () => import('../pages/Footerlink/Components/Codeofethics.vue'),
      },
      {
        path: '/p/Practiceguideline',
        name: 'Practiceguideline',
        component: () => import('../pages/Footerlink/Components/Practiceguideline.vue'),
      },
      {
        path: '/p/Consultation',
        name: 'Consultation',
        component: () => import('../pages/Footerlink/Components/Consultation.vue'),
      },
      {
        path: '/p/Introducepinxdetail',
        name: 'Introducepinxdetail',
        component: () => import('../pages/Footerlink/Components/Introducepinxdetail.vue'),
      },
      {
        path: '/p/Pinxhistory',
        name: 'Pinxhistory',
        component: () => import('../pages/Footerlink/Components/Pinxhistory.vue'),
      },
      {
        path: '/p/Pinxresortmoveinfo',
        name: 'Pinxresortmoveinfo',
        component: () => import('../pages/Footerlink/Components/Pinxresortmoveinfo.vue'),
      },
      {
        path: '/p/Resortmoveinfo',
        name: 'Resortmoveinfo',
        component: () => import('../pages/Footerlink/Components/Resortmoveinfo.vue'),
      },
      {
        path: '/p/Pinxpolicy/:idx',
        name: 'Pinxpolicy',
        component: () => import('../pages/Footerlink/Pinxpolicy.vue'),
      },
      {
        path: '/p/Direction',
        name: 'Direction',
        component: () => import('../pages/Footerlink/Direction.vue'),
      },
      {
        path: '/p/TOSpinx',
        name: 'TOSpinx',
        component: () => import('../pages/Footerlink/TOSpinx.vue'),
      },
      {
        path: '/p/Ethicalmanagement',
        name: 'Ethicalmanagement',
        component: () => import('../pages/Footerlink/Ethicalmanagement.vue'),
      },
      {
        path: '/p/Rejectionemail',
        name: 'Rejectionemail',
        component: () => import('../pages/Footerlink/Rejectionemail.vue'),
      },
      {
        path: '/p/EventMain',
        name: 'EventMain',
        component: () => import('../pages/Footerlink/EventMain.vue'),
      },
      {
        path: '/p/QnaMain',
        name: 'QnaMain',
        component: () => import('../pages/Footerlink/QnaMain.vue'),
      },
      {
        path: '/p/Marketingaffiliate',
        name: 'Marketingaffiliate',
        component: () => import('../pages/Footerlink/Marketingaffiliate.vue'),
      },
      {
        path: '/p/Marketingcontact',
        name: 'Marketingcontact',
        component: () => import('../pages/Footerlink/Marketingcontact.vue'),
      },
      {
        path: '/p/MarketingMain',
        name: 'MarketingMain',
        component: () => import('../pages/Footerlink/MarketingMain.vue'),
      },
      {
        path: '/p/Reservpleisure/:idx',
        name: 'Reservpleisure',
        component: () => import('../pages/Pleisure/Components/reservpleisure.vue'),
      },
      // {
      //   path: '/p/MemberReserv',
      //   name: 'MemberReserv',
      //   component: () => import('../pages/MemberReserv/MemberReserv.vue'),
      // },
      {
        path: '/p/Mall',
        name: 'Mall',
        component: () => import('../pages/mall/mall.vue'),
      },
      {
        path: '/noHeader',
        name: '',
        component: () => import(/* webpackChunkName: "" */ '../components/IndexNoHeader.vue'),
        children: [
          {
            path: 'p/bizSirenCallBackPhone',
            name: 'BizSirenCallBackPhone',
            component: () => import('../pages/Login/BizSirenCallBackPhone.vue'),
          },
          {
            path: 'p/bizSirenCallBackIpin',
            name: 'bizSirenCallBackIpin',
            component: () => import('../pages/Login/BizSirenCallBackIpin.vue'),
          },
          {
            path: 'p/login/navercallback',
            name: 'NaverCallBack',
            component: () => import('../pages/Login/NaverCallBack.vue'),
          },
          {
            path: 'p/login/kakaocallback',
            name: 'KakaoCallBack',
            component: () => import('../pages/Login/KakaoCallBack.vue'),
          },
        ],
      },
      // WARN: 아래 내용 절대 옮기지 마시오! //404 Notfound 시 홈으로 리다이렉팅
      {
        path: '*',
        redirect: '/',
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  // to: 이동할 url에 해당하는 라우팅 객체
  if (
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    if (!store.getters['Auth/isLoggedIn']) {
      // 이동할 페이지에 인증 정보가 필요하면 경고 창을 띄우고 페이지 전환은 하지 않음
      alert('로그인이 필요합니다!');
      next({name: 'Login', params: {returnUrl: to.path}});
    } else next();
  } else {
    next(); // 페이지 전환
  }

  if (to.path.startsWith('/p/Hr')) {
    window.location.href = 'https://skpinx.skcareers.com/';
  } else {
    next();
  }
});

export default router;
