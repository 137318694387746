import {PMSResDto} from '@/models';
import moment from 'moment';

export type QueryByPkgOrRoom = 'None' | 'Pkg' | 'Room';
export type HOTEL_TYPE = 'P' | 'A';
export const PMS_YYYYMMDD = 'YYYYMMDD';

export interface VCalendarDateExp {
  start: Date;
  end: Date | undefined;
}

export interface VCalendarAttrExp {
  highlight: {
    color: string;
    fillMode: string;
  };
  dates: Date;
}

export class HotelRentItem {
  // "upjangCode": "2202",
  // "salePrice": 30000,
  // "menuCode": "G704000002",
  // "upjangName": "디아넥스대여",
  // "menuName": "V.I.B SET",
  // "remaRentCnt": 39,
  // "corpCode": "01"

  // API에서 보내주는 정보
  upjangCode?: string;
  salePrice = 0;
  menuCode: string;
  upjangName?: string;
  menuName?: string;
  remaRentCnt?: number;
  corpCode?: string;

  // 사용자입력
  checked = false;
  disabled = false;
  // 내부처리 부가설명
  showMenuName: string;
  description = '';
  constructor(menuCode: string, showMenuName: string, description: string) {
    this.menuCode = menuCode;
    this.showMenuName = showMenuName;
    this.description = description;
  }
  setUnChecked() {
    this.checked = false;
  }

  setChecked() {
    this.checked = true;
  }
}
export interface PMS_Res_HotelRentItemList extends PMSResDto {
  items: HotelRentItem[];
}

export class HotelBookRoomInfo {
  //checked = true;
  // name = '';
  adultCnt = 0;
  childCnt = 0;
  birthDate: number[] = [];

  // 조식 (현재 사용하지 않지만 API에 보내줘야함)
  option_BreakfastCnt = 0;
  // 침구 수 (개당 33000원 고정가격)
  option_BedCnt = 0;

  // 디아넥스 호텔에만 해당, 유아용품 배타적 선택 규칙 때문에 용품순서 및 menu 코드 하드코딩해서 처리
  // 가격정보는 서버에서 받아오는걸로
  rentList: HotelRentItem[] = [
    new HotelRentItem('G704000002', 'V.I.B 세트', '(BABYⅠ세트 + BABYⅡ세트)'),
    new HotelRentItem('G704000003', 'BABY Ⅰ 세트', '(젖병 소독기, 분유포트, 보틀워머)'),
    new HotelRentItem('G704000004', 'BABY ⅠⅠ 세트', '(유아욕조, 유아변기커버&디딤대, 목욕의자)'),
  ];

  _showDetails = true;

  constructor(adultCnt?: number) {
    this.adultCnt = adultCnt ?? 0;
    //this.birthDate = Array(8);
  }

  //주의 + 호텔별 다르게 적용되는 Multiplier를 곱해야 최종 금액이 나온다
  get calcItemAmtBed() {
    const bedTotal = 33000 * this.option_BedCnt;
    return bedTotal;
  }

  get calcItemAmtRent() {
    const rentTotal = this.rentList.reduce((a: number, o) => a + (o.checked ? o.salePrice : 0), 0);
    return rentTotal;
  }

  get rentInfoString() {
    return this.rentList
      .filter(x => x.checked)
      .map(x => `${x.menuCode}|1`)
      .join('|');
  }
  resetOptionsSelected() {
    this.rentList.forEach(x => (x.checked = false));
    this.option_BedCnt = 0;
    this.option_BreakfastCnt = 0;
  }
}

export class PMSReqHotelReservNew {
  hotelType = '';
  cciDate = '';
  ccoDate = '';
  roomCnt = 0;
  adultCnt = 0;
  childCnt = 0;

  // peopleInfo = '';   //reservNewPeopleInfo
  // childInfo = '';    //reservNewChildInfo
  // pkgCode = '';      //selectedPkg
  // roomType = '';     //selectedRoom
  // optionInfo = '';   //동적으로 생성
  reservRemarks = '';
  cardIssueCode = '';

  bookName = ''; //비회원 처리용
  bookHpNo = ''; //비회원 처리용
  bookSmsSendYn = ''; //비회원 처리용

  _cardNo1 = '';
  _cardNo2 = '';
  _cardNo3 = '';
  _cardNo4 = '';

  _cardYy = '';
  _cardMm = '';

  _agreeAll = false; //모두 동의
  _agreeCancel = false; //취소규정 동의
  _agreePersonalCollect = false; // 개인정보 수집 동의
  _agreePersonalGive = false; // 개인정보 위탁 동의

  get cardNo() {
    return `${this._cardNo1}${this._cardNo2}${this._cardNo3}${this._cardNo4}`;
  }
  get cardYymm() {
    return `${this._cardYy}${this._cardMm}`;
  }

  get isValidCardYYMM() {
    if (this._cardYy === '' || this._cardMm === '') {
      return false;
    }

    return true;
  }

  resetStep2() {
    this.roomCnt = 0;
    this.adultCnt = 0;
    this.childCnt = 0;
  }

  resetStep4() {
    this.reservRemarks = '';
    this.cardIssueCode = '';
    this._cardNo1 = '';
    this._cardNo2 = '';
    this._cardNo3 = '';
    this._cardNo4 = '';
    this._cardYy = '';
    this._cardMm = '';
    this._agreeAll = false; //모두 동의
    this._agreeCancel = false; //취소규정 동의
    this._agreePersonalCollect = false; // 개인정보 수집 동의
    this._agreePersonalGive = false; // 개인정보 위탁 동의
  }

  constructor(hotelType: HOTEL_TYPE) {
    this.hotelType = hotelType;
  }
}

// H002 호텔공통 - 일자별 포캐스팅
export interface PMS_Res_HotelForecastListItem {
  saleDate: string;
  dayCode: string;
  dayName: string;
  dayTypeCode: string;
  dayTypeName: string;
  rsvSum: number;
  janSum: number;
}

export interface PMS_Res_HotelForecastList extends PMSResDto {
  items: PMS_Res_HotelForecastListItem[];
}

export interface BoardArticleInfo {
  // board에서 설정하는것
  _imgPath: string;
  _sortIdx: number;
  _title: string;
  _isView: boolean;
  _memo: string;
}

// H003 호텔공통 - 객실별 조회
export interface PMS_Res_HotelRoomListItem extends BoardArticleInfo {
  roomType: string;
  roomName: string;
  minAmt: number;
  validCnt: number | null;
  _pkgList: PMS_Res_HotelRoomToPkgListItem[];

  _title: string;
  _memo: string;
}

export interface PMS_Res_HotelRoomList extends PMSResDto {
  items: PMS_Res_HotelRoomListItem[];
}

// H004 호텔공통 - 객실>상품 조회
export interface PMS_Res_HotelRoomToPkgListItem {
  pkgCode: string;
  pkgName: string;
  minAmt: number;
  validCnt: number;
}

export interface PMS_Res_HotelRoomToPkgList extends PMSResDto {
  items: PMS_Res_HotelRoomToPkgListItem[];
}

export interface PMS_Res_HotelRoomToPkgListItem1 {
  pkgCode: string;
  pkgName: string;
  validCnt: number;
  roomType: string;
  maxAmt: number;
  minAmt: number;
}

export interface PMS_Res_HotelRoomToPkgList1 extends PMSResDto {
  items: PMS_Res_HotelRoomToPkgListItem1[];
}

// H005 호텔공통 - 상품별 조회
export interface PMS_Res_HotelPkgListItem extends BoardArticleInfo {
  pkgCode: string;
  pkgName: string;
  minAmt: number;
  maxAmt: number;
  _roomList: PMS_Res_HotelRoomListItem[];
  _title: string;
  _memo: string;
}

export interface PMS_Res_HotelPkgList extends PMSResDto {
  items: PMS_Res_HotelPkgListItem[];
}

// H006 호텔공통 - 상품>객실 조회 : H003 과 같은 결과로 사용함
// export interface PMS_Res_HotelPkgToRoomListItem {
//   roomType: string;
//   roomName: string;
//   minAmt: number;
//   validCnt: number;
//   _pkgList: PMS_Res_HotelPkgListItem[];
// }

export interface PMS_Res_HotelPkgToRoomList extends PMSResDto {
  items: PMS_Res_HotelRoomListItem[];
}

// H008 호텔공통 - 일자별요금 조회
export interface PMS_Res_HotelPkgDateAmtListItem {
  roomSeq: number;
  saleDate: string;
  roomAmt: number;
  addAmt: number; //인원추가요금
  totalAmt: number;
  optionAmt?: number; // 패키지부대업장요금? //패키지 선택시에만 조회됨
  membershipDcAmt: number;
  membershipName: string;
}

export interface PMS_Res_HotelPkgDateAmtList extends PMSResDto {
  items: PMS_Res_HotelPkgDateAmtListItem[];
}

// H011 호텔공통 - 예약목록 조회
export interface PMS_Res_HotelReservListItem {
  reservNo: string;
  reservSeq: number;
  pkgCode: string;
  pkgName: string;
  roomType: string;
  roomTypeName: string;
  stayinChild: number;
  cciDate: string;
  ccoDate: string;
  stayinAdult: number;
  addOption: string;
  bookName: string;
  status: string;
  statusText: string;
  sourceCd: string;
  sourceCdText: string;
  hpNo: string;
  membershipDcAmt: number; // 할인금
  viewAmt: number;
  addAmt: number; // 인원추가음
  addOptionAmt: number; // 패키지 부대업장 요금
  cardNo: string;
  cardYymm: string;
  reservRemarks: string;

  // 자체적으로 생성/매핑 하는 값
  _stayingDate: string;
  _roomTypeName: string;
  _pkgName: string;
}

export interface PMS_Res_HotelReservList extends PMSResDto {
  items: PMS_Res_HotelReservListItem[];
}

// H009 예약등록 응답
export interface PMS_Res_HotelReservNew extends PMSResDto {
  obj: {
    reservNoInfo: string;
  };
}

// H001 호텔공통 - 패키지 상품 조회
export interface HotelAllPkgListItem {
  pkgCode: string;
  pkgName: string;
  pkgEntName: string;
  defaultDayCnt: number;
  maxDayCnt: number;
  stDate: string;
  edDate: string;
  useYn: string;
  remarks: string;
}
export interface PMS_Res_HotelAllPkgList extends PMSResDto {
  items: HotelAllPkgListItem[];
}
// H013 호텔공통 - 객실 타입 조회
export interface HotelRoomTypeListItem {
  roomType: string;
  roomTypeName: string;
  orderSeq: string;
}
export interface PMS_Res_HotelRoomTypeList extends PMSResDto {
  items: HotelRoomTypeListItem[];
}
