<template>
  <div class="container">
    <div>
      <h2>RESTAURANT</h2>
    </div>
    <div>
      <p>
        제주를 재해석한 고품격 퓨전 로컬 메뉴<br />
        엄선된 제주의 귀하고 신선한 식재료를 사용하여 최고의 맛을 선사합니다.
      </p>
    </div>
    <div ref="imgGrid" class="imgGrid" :style="{height: fixedHeight + 'px'}">
      <div
        class="leftGrid"
        :style="{
          height: fixedHeight + 'px',
          'margin-bottom': '4em',
        }"
      >
        <div Class="gridItem1" v-show="!isMobile">
          <a href="https://pinxgc.thepinx.co.kr/p/Restaurant">
            <div class="sItem1">
              <div class="textContainer">
                <div class="sTitle">GOLF CLUB</div>
                <div class="rowSpace"></div>
                <div class="sDesc">골프코스와 수평선 전망이 펼쳐지는</div>
                <div class="sDesc">클럽하우스 레스토랑</div>
              </div>
            </div>
          </a>
        </div>

        <div Class="gridItem3" v-show="!isMobile">
          <a href="https://theannex.thepinx.co.kr/p/Restaurant">
            <div class="sItem3">
              <div class="textContainer">
                <div class="sTitle">THE ANNEX</div>
                <div class="rowSpace"></div>
                <div class="sDesc">호수풍경과 마주하며 조식, 티타임,</div>
                <div class="sDesc">제주 이색메뉴를 즐길 수 있는 레스토랑</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="centerBox" :style="{height: fixedHeight + 'px'}">
        <SlideCarousels
          :autoplay="true"
          :item-total-count="items.length"
          :items="items"
          :breakpoints="breakpoints"
          :slide-number="1"
          :arrows="false"
          :bullets="false"
          :fixed-height="fixedHeight"
        >
          <template v-slot:slides>
            <VueperSlide v-for="(item, index) in items" :key="index" :href="item.url">
              <template #content>
                <div ref="top">
                  <RestaurantItem :item="item" @ImgLoaded="refreshHeight" />
                </div>
              </template>
            </VueperSlide>
          </template>
        </SlideCarousels>
      </div>
      <div class="rightGrid" :style="{height: fixedHeight + 'px', 'margin-bottom': '4em'}">
        <div Class="gridItem2" v-show="!isMobile">
          <a href="https://podo.thepinx.co.kr/p/Restaurant">
            <div class="sItem2">
              <div class="textContainer">
                <div class="sTitle">PODO HOTEL</div>
                <div class="rowSpace"></div>
                <div class="sDesc">제주 로컬 퓨전 요리를</div>
                <div class="sDesc">선보이는 고품격 레스토랑</div>
              </div>
            </div>
          </a>
        </div>

        <div Class="gridItem4" v-show="!isMobile">
          <a href="https://biotopia.thepinx.co.kr/p/Restaurant">
            <div class="sItem4">
              <div class="textContainer">
                <div class="sTitle">BIOTOPIA RESTAURANT</div>
                <div class="rowSpace"></div>
                <div class="sDesc">정통 화덕피자와 양식요리를</div>
                <div class="sDesc">선보이는 프라이빗 레스토랑</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="mobileGrid" v-show="isMobile">
      <div Class="gridItem1">
        <a href="https://pinxgc.thepinx.co.kr/p/Restaurant">
          <div class="sItem1">
            <div class="textContainer">
              <div class="rowSpaceTitle"></div>
              <div class="sTitle">GOLF CLUB</div>
              <div class="rowSpace"></div>
              <div class="sDesc">골프코스와 수평선 전망이 펼쳐지는</div>
              <div class="sDesc">클럽하우스 레스토랑</div>
            </div>
          </div>
        </a>
      </div>
      <div Class="gridItem3">
        <a href="https://theannex.thepinx.co.kr/p/Restaurant">
          <div class="sItem3">
            <div class="textContainer">
              <div class="rowSpaceTitle"></div>
              <div class="sTitle">THE ANNEX</div>
              <div class="rowSpace"></div>
              <div class="sDesc">호수풍경과 마주하며 조식, 티타임,</div>
              <div class="sDesc">제주 이색메뉴를 즐길 수 있는 레스토랑</div>
            </div>
          </div>
        </a>
      </div>
      <div Class="gridItem2">
        <a href="https://podo.thepinx.co.kr/p/Restaurant">
          <div class="sItem2">
            <div class="textContainer">
              <div class="rowSpaceTitle"></div>
              <div class="sTitle">PODO HOTEL</div>
              <div class="rowSpace"></div>
              <div class="sDesc">제주 로컬 퓨전 요리를</div>
              <div class="sDesc">선보이는 고품격 레스토랑</div>
            </div>
          </div>
        </a>
      </div>
      <div Class="gridItem4">
        <a href="https://biotopia.thepinx.co.kr/p/Restaurant">
          <div class="sItem4">
            <div class="textContainer">
              <div class="rowSpaceTitle"></div>
              <div class="sTitle">BIOTOPIA RESTAURANT</div>
              <div class="rowSpace"></div>
              <div class="sDesc">정통 화덕피자와 양식요리를</div>
              <div class="sDesc">선보이는 프라이빗 레스토랑</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SlideCarousels from '@/components/SlideCarousels';
import {VueperSlide} from 'vueperslides';
import RestaurantItem from '@/pages/MainPage/Components/RestaurantItem';

export default {
  name: 'Restaorant',
  components: {
    RestaurantItem,
    SlideCarousels,
    VueperSlide,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      windowWidth: 0,
      isMobile: false,
      fixedHeight: '0px',
      breakpoints: {
        992: {
          bullets: false,
          arrows: false,
          visibleSlides: 1,
          touchable: false,
          disableArrowsOnEdges: true,
          fade: true,
          animation: false,
        },
      },
    };
  },
  watch: {
    windowWidth(newHeight, oldHeight) {
      this.refreshHeight();
    },
  },
  created() {
    this.isMobile = window.innerWidth < 992;
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    this.refreshHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 992;
      this.windowWidth = window.innerWidth;
    },
    refreshHeight() {
      this.$nextTick(() => {
        console.log('HEIGHT>>>!!!2', this.$refs.top);
        if (this.$refs.top !== undefined) {
          let px = this.$refs.top[0].clientHeight;
          this.fixedHeight = `${px}px`;
          console.log('HEIGHT>>>!!!2', this.fixedHeight);
        }

        // if (this.$refs.imgGrid !== undefined) {
        //   console.log('HEIGHT>>>!!!1-imgGrid', this.fixedHeight);
        //   this.$refs.imgGrid.clientHeight = this.fixedHeight;
        // }
      });
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: 8rem;
  text-align: center;
  color: #222222;
  font-family: 'Jeju Myeongjo', sans-serif;
  font-weight: 700;
}

p {
  padding-top: 1rem;
  height: 110px;
  text-align: center;
  color: #222;
  font-family: Nanum Gothic, sans-serif;
  font-size: 1em !important;
}

.imgGrid {
  display: flex;

  .leftGrid {
    display: block;
    width: 25%;

    .gridItem1 {
      height: 50%;
      padding-right: 10px;
      padding-bottom: 5px;
    }

    .gridItem3 {
      height: 50%;
      padding-right: 10px;
      padding-top: 5px;
    }
  }

  .centerBox {
    width: 50%;
  }

  .rightGrid {
    width: 25%;

    .gridItem2 {
      height: 50%;
      padding-left: 10px;
      padding-bottom: 5px;
    }

    .gridItem4 {
      height: 50%;
      padding-left: 10px;
      padding-top: 5px;
    }
  }
}

.textContainer {
  width: 100%;
}

.rowSpace {
  height: 1rem;
}
.rowSpaceTitle {
  height: 5rem;
}
.rowSpaceDesc {
  height: 1rem;
}
.sTitle {
  color: white;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}
.sDesc {
  color: white;
  text-align: center;
  font-size: 0.8rem;
}
.sItem1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url('~@/assets/images/main/home-restaurant-thumb-01.jpg');
}
.sItem2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url('~@/assets/images/main/home-restaurant-thumb-02.jpg');
}
.sItem3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url('~@/assets/images/main/home-restaurant-thumb-03.jpg');
}
.sItem4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url('~@/assets/images/main/home-restaurant-thumb-04.jpg');
}

@media (max-width: 992px) {
  .imgGrid {
    display: flex;

    .leftGrid {
      display: block;
      width: 0%;

      .gridItem1 {
        height: 50%;
        padding-right: 10px;
        padding-bottom: 5px;
      }

      .gridItem3 {
        height: 50%;
        padding-right: 10px;
        padding-top: 5px;
      }
    }

    .centerBox {
      width: 100%;
    }

    .rightGrid {
      width: 0%;

      .gridItem2 {
        height: 50%;
        padding-left: 10px;
        padding-bottom: 5px;
      }

      .gridItem4 {
        height: 50%;
        padding-left: 10px;
        padding-top: 5px;
      }
    }
  }

  .rowSpaceTitle {
    height: 2rem;
  }

  .mobileGrid {
    display: block;
    width: 100%;

    .gridItem1 {
      flex: 1;
      width: 100%;
      height: 100%;
      padding-right: 0px;
      padding-bottom: 0px;
      .sItem1 {
        background-image: url('~@/assets/images/main/home-restaurant-thumb-01.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        .textContainer {
          padding-bottom: 2rem;
        }
      }
    }
    .gridItem3 {
      flex: 1;
      width: 100%;
      height: 100%;
      padding-right: 0px;
      padding-top: 0px;
      .sItem3 {
        flex: 1;
        //width: 100%;
        //height: 100%;
        background-image: url('~@/assets/images/main/home-restaurant-thumb-03.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        .textContainer {
          padding-bottom: 2rem;
        }
      }
    }
    .gridItem2 {
      flex: 1;
      width: 100%;
      height: 100%;
      padding-left: 0px;
      padding-bottom: 0px;
      .sItem2 {
        flex: 1;
        //width: 100%;
        //height: 100%;
        background-image: url('~@/assets/images/main/home-restaurant-thumb-02.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        .textContainer {
          padding-bottom: 2rem;
        }
      }
    }
    .gridItem4 {
      flex: 1;
      width: 100%;
      height: 100%;
      padding-left: 0px;
      padding-top: 0px;
      .sItem4 {
        flex: 1;
        //width: 100%;
        //height: 100%;
        background-image: url('~@/assets/images/main/home-restaurant-thumb-04.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        .textContainer {
          padding-bottom: 2rem;
        }
      }
    }
  }
}
</style>
