const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DATETIME_FORMAT_DISPLAY = 'YYYY-MM-DD HH:mm:ss';
const DATEONLY_FORMAT = 'YYYY-MM-DD';
const TIMEONLY_FORMAT = 'HH:mm';

const WORK_DAY_KOREAN = [
  {value: 0, text: '일'},
  {value: 1, text: '월'},
  {value: 2, text: '화'},
  {value: 3, text: '수'},
  {value: 4, text: '목'},
  {value: 5, text: '금'},
  {value: 6, text: '토'},
];
const OPTIONS_MONTHS = [
  {value: 1, text: '1월'},
  {value: 2, text: '2월'},
  {value: 3, text: '3월'},
  {value: 4, text: '4월'},
  {value: 5, text: '5월'},
  {value: 6, text: '6월'},
  {value: 7, text: '7월'},
  {value: 8, text: '8월'},
  {value: 9, text: '9월'},
  {value: 10, text: '10월'},
  {value: 11, text: '11월'},
  {value: 12, text: '12월'},
];
const OPTIONS_MONTHS_WITHALL = [
  {value: -1, text: '전체'},
  {value: 1, text: '1월'},
  {value: 2, text: '2월'},
  {value: 3, text: '3월'},
  {value: 4, text: '4월'},
  {value: 5, text: '5월'},
  {value: 6, text: '6월'},
  {value: 7, text: '7월'},
  {value: 8, text: '8월'},
  {value: 9, text: '9월'},
  {value: 10, text: '10월'},
  {value: 11, text: '11월'},
  {value: 12, text: '12월'},
];

const CONST_HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
const CONST_MINUTES = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
];

const CONST = {
  WORK_DAY_KOREAN,
  OPTIONS_MONTHS,
  CONST_HOURS,
  CONST_MINUTES,
  DATETIME_FORMAT,
  DATEONLY_FORMAT,
  DATETIME_FORMAT_DISPLAY,
  TIMEONLY_FORMAT,
  OPTIONS_MONTHS_WITHALL,
};

export default CONST;
