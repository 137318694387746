/* ====================================================================== */
/* Vue
/* ====================================================================== */
import './class-component-hooks';

import Vue from 'vue';
import App from './App.vue';
/* ====================================================================== */
/* Router, Vuex
/* ====================================================================== */
import router from './router';
import store from './store';

/* ====================================================================== */
/* PlugIns
/* ====================================================================== */
import DefaultPlugIn from '@/plugIn/DefaultPlugin';
Vue.use(DefaultPlugIn);

/* ====================================================================== */
/* Settings
/* ====================================================================== */
const options = {name: 'lodash'};
Vue.config.productionTip = false;
require('es6-promise').polyfill();

import './assets/css/materialdesignicons.min.css';
// import 'swiper/swiper-bundle.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
//

const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  duration: 3000,
  easing: 'ease',
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  screens: {
    xs: '767px',
    sm: '991px',
    md: '1199px',
    lg: '1200px',
  },
});

import VueDaumPostcode from 'vue-daum-postcode';

Vue.use(VueDaumPostcode);

// import Scrollspy from 'vue2-scrollspy';
// import VueYoutube from 'vue-youtube';
// import vueVimeoPlayer from 'vue-vimeo-player';
// import VueMasonry from 'vue-masonry-css';
//

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {ValidationProvider, extend, ValidationObserver} from 'vee-validate';
import {required, min_value, min, regex, email, length} from 'vee-validate/dist/rules';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// validation
extend('required', required);
extend('min', min);
extend('min_value', min_value);
extend('regex', regex);
extend('email', email);
extend('date', {
  validate: value => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value),
  message: 'Date must be in YYYY-MM-DD format.',
});
extend('length', length);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// Vue.use(Scrollspy);
// Vue.use(VueYoutube)
// Vue.use(vueVimeoPlayer)
// Vue.use(VueMasonry)

// locale
import moment from 'moment';
import 'moment/locale/ko';
moment.locale('ko');

// gtag
import VueGtag from 'vue-gtag';

import i18n from './i18n';
Vue.use(
  VueGtag,
  {
    config: {id: 'AW-780879941'},
    includes: [{id: 'G-DD7PXQ8M5H'}, {id: 'GTM-NDVFBQZ'}],
  },
  router,
);

// 쿠키 접근 (팝업등에서 사용)
import VueCookies from 'vue-cookies';
//쿠키를 사용한다.
Vue.use(VueCookies);

//쿠키의 만료일은 7일이다. (글로벌 세팅)
Vue.$cookies.config('7d');

//vuex initLogin 을 무엇보다 먼저 실행하여 로그인 상태, 유저 정보 초기화 함
store.dispatch('Auth/initLogin').finally(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
});
