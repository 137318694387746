<template>
  <div class="outterBox">
    <div class="container">
      <h2>SPECIAL OFFERS</h2>
      <SlideCarousels
        :fade="false"
        :item-total-count="items.length"
        :items="items"
        :breakpoints="breakpoints"
        :slide-number="3"
        :gap="3"
        :fixed-height="fixedHeight"
      >
        <template v-slot:slides>
          <VueperSlide lazy v-for="item in items" :key="item.id" :image="item.image">
            <template #content>
              <div ref="top">
                <SpecialOffersItem :item="item" @ImgLoaded="refreshHeight" />
              </div>
            </template>
          </VueperSlide>
        </template>
      </SlideCarousels>
    </div>
  </div>
</template>

<script>
import SlideCarousels from '@/components/SlideCarousels';
import {VueperSlide} from 'vueperslides';
import SpecialOffersItem from '@/pages/MainPage/Components/SpecialOffersItem';
import boardService from '@/services/board.service';
import {BOARD_ID} from '@/util/BoardConst';
import {
  ANNEX_URL,
  BOARD_ANNEX_ID_PKG,
  BOARD_ANNEX_ID_PKG_NEW,
  BOARD_ID_PKG,
  BOARD_ID_PKG_NEW,
  BOARD_PODO_ID_PKG,
  BOARD_PODO_ID_PKG_NEW,
  PODO_URL,
} from './hotelBoardID';
import {BoardArticle} from '@/models';

export default {
  name: 'SpecialOffers',
  data() {
    return {
      windowWidth: 0,
      fixedHeight: '547px',
      items: [],
      breakpoints: {
        992: {
          bullets: false,
          arrows: false,
          visibleSlides: 1,
          touchable: false,
          disableArrowsOnEdges: true,
          fade: true,
          animation: false,
        },
      },
    };
  },
  components: {
    SlideCarousels,
    VueperSlide,
    SpecialOffersItem,
  },

  async created() {
    const podo = await this.getItems(BOARD_PODO_ID_PKG, BOARD_PODO_ID_PKG_NEW, PODO_URL);
    const annex = await this.getItems(BOARD_ANNEX_ID_PKG, BOARD_ANNEX_ID_PKG_NEW, ANNEX_URL);

    this.items = [...podo, ...annex];
    console.log('this.items', this.items);
    window.addEventListener('resize', this.onResize);

    this.$nextTick(() => {
      console.log('HEIGHT>>> AXIOS', this.$refs.top);
      this.refreshHeight();
    });
  },
  watch: {
    windowWidth(newHeight, oldHeight) {
      this.refreshHeight();
    },
  },
  mounted() {
    this.refreshHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    async getItems(bid_pkg, bid_pkg_new, domain) {
      let detailItems = (
        await boardService.getArticleListWithMemo(
          bid_pkg_new,
          1,
          100,
          'BoardArticleDetailWithFiles',
          'id',
          'desc',
          true,
        )
      )._embedded.boardArticles.map(x => {
        let newX = new BoardArticle(x);
        //도메인 박아서 링크 생성
        newX._linkurl = `${domain}/p/ItemDetail/${newX.subtitle1}`;
        return newX;
      });

      for (const detailItem of detailItems) {
        const data = JSON.parse(detailItem.memo);
        if (data.thumbPic1 && data.thumbPic1 !== '') {
          detailItem.thumbnailUrl = data.thumbPic1;
        }

        let pkgitems = (
          await boardService.getArticleList(
            bid_pkg,
            1,
            100,
            'BoardArticleDetailWithFiles',
            'sortIdx',
            'desc',
            true,
            detailItem.subtitle1,
          )
        )._embedded.boardArticles.map(x => new BoardArticle(x));

        if (pkgitems.length > 0) {
          detailItem.attachFiles[0] = pkgitems[0].attachFiles[0];
          console.log(detailItem);
        }
      }

      return detailItems;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    refreshHeight() {
      this.$nextTick(() => {
        console.log('HEIGHT>>>!!!0', this.$refs.top);
        if (this.$refs.top !== undefined) {
          let px = this.$refs.top[0].clientHeight;
          this.fixedHeight = `${px + 20}px`;
          console.log('HEIGHT>>>!!!0', this.fixedHeight);
        }
      });
    },
  },
};
</script>

<style></style>

<style scoped lang="scss">
h2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: 8rem;
  text-align: center;
  color: #222222;
  font-family: 'Jeju Myeongjo', sans-serif;
  font-weight: 700;
}

.outterBox {
  border-top: 1px solid #eaeaea;
}
</style>
