import axios, {AxiosInstance} from 'axios';
import {User} from '@/models';
import {HrToken} from '@/model-hr';
import store from '../store';

const AXIOS = axios.create({
  // headers Bearer 토큰은 App.vue 에서 모니터링해서 설정됨.
  baseURL: '/api',
  timeout: 600000,
});
setInterceptors(AXIOS);
let isTokenRefreshing = false;
const refreshSubscribers: any[] = [];

const onTokenRefreshed = (accessToken: any) => {
  refreshSubscribers.map(callback => callback(accessToken));
};

const addRefreshSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

function setInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      config.headers.common['x-authenticate-token'] = store.getters['Auth/hrAccessToken'];
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      //console.error(error);
      const {
        config,
        response: {status},
      } = error;

      const originalRequest = config;
      if (status === 401) {
        if (!isTokenRefreshing) {
          // isTokenRefreshing이 false인 경우에만 token refresh 요청
          isTokenRefreshing = true;

          //TODO : 아래 과정을 store/Auth 내부로 모두 옮겨야 함.
          store
            .dispatch('Auth/getHrToken')
            .then((response: {data: any}) => {
              const data = response.data;
              if (data.state === 'success') {
                const refreshToken = data.data as HrToken;
                sessionStorage.setItem('hrToken', JSON.stringify(refreshToken));
                store.commit('Auth/updateHrToken', refreshToken);
                config.headers['x-authenticate-token'] = refreshToken.access_token;
                originalRequest.headers['x-authenticate-token'] = refreshToken.access_token;
                // 새로운 토큰으로 지연되었던 요청 진행
                onTokenRefreshed(refreshToken.access_token);
                return instance(originalRequest);
              } else {
                alert('인재DB 서비스에 연결할수 없습니다\n잠시후에 다시 시도해주세요');
                return error;
              }
            })
            .catch((error: any) => {
              alert('인재DB 서비스에 연결할수 없습니다\n잠시후에 다시 시도해주세요');
              // store.dispatch('Auth/signOut');
              // window.location.href = '/p/login';
            })
            .finally(() => {
              isTokenRefreshing = false;
            });
        }
        // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        return new Promise(resolve => {
          addRefreshSubscriber((accessToken: any) => {
            originalRequest.headers['x-authenticate-token'] = accessToken;
            resolve(instance(originalRequest));
          });
        });
      }
      return Promise.reject(error);
    },
  );
  return instance;
}

export default AXIOS;
