export type YesOrNo = 'Y' | 'N';

export type SexType = 'M' | 'F';

export interface FamilyInfo {
  guestNo: string; //	고객번호
  guestName: string; //	고객명
  relation: string; //	관계
  relationName: string; //	관계명
  guestType: string; //	고객유형
  guestTypeName: string; //	고객유형명
  hpNo: string; //	휴대폰번호
}
export interface GolfLimitInfo {
  yAllLimit: number; //년 총한도 잔여
  yAllTotal: number; //년 총한도 전체
  yWeekdayLimit: number; //년 본인주중 잔여
  yWeekdayTotal: number; //년 본인주중 전체
  yWeekendLimit: number; //년 본인주말 잔여
  yWeekendTotal: number; //년 본인주말 전체
  yWeekdayRecomLimit: number; //년 추천주중 잔여
  yWeekdayRecomTotal: number; //년 추천주중 전체
  yWeekendRecomLimit: number; //년 추천주말 잔여
  yWeekendRecomTotal: number; //년 추천주말 전체
  mAllLimit: number; //월 총한도 잔여
  mAllTotal: number; //월 총한도 전체
  mWeekdayLimit: number; //월 본인주중 잔여
  mWeekdayTotal: number; //월 본인주중 전체
  mWeekendLimit: number; //월 본인주말 잔여
  mWeekendTotal: number; //월 본인주말 전체
  mWeekdayRecomLimit: number; //월 추천주중 잔여
  mWeekdayRecomTotal: number; //월 추천주중 전체
  mWeekendRecomLimit: number; //월 추천주말 잔여
  mWeekendRecomTotal: number; //월 추천주말 전체
}

export interface MemberInfo {
  memNo: string; //	회원번호
  memType: string; //	고객구분
  memTypeName: string; //	고객구분명
  guestType: string; //	고객유형
  guestTypeName: string; //	고객유형명
  allPoint: number; //	총 포인트
  allRemainPoint: number; //	잔여 포인트
  familyInfoArr: FamilyInfo[];
  golfLimitInfo?: GolfLimitInfo;
}

export interface UserLogin {
  user: User;
  pinxToken: string; // 액션용 토큰
}

export interface GuestUser {
  guestName: string; //	고객명
  guestNo: string; //	고객번호
  pinxToken: string;
  pinxRefreshToken: string;
}
export interface User {
  guestName: string; //	고객명
  guestNo: string; //	고객번호
  hpNo: string; //	휴대폰번호
  smsSendYn: YesOrNo; //	sms수신여부 (Y/N)
  email: string; //	이메일주소
  emailYn: string; //	이메일수신여부 (Y/N)
  sex: SexType; //	성별 (M/F)
  birthday: string; //	생년월일
  lunarCode: string; //	음/양 구분 (1 양력 /2 음력)
  joinDate: string; //	가입일자
  areaType: string; //	지역코드
  areaTypeName: string; //	지역명
  snsType: string; //	SNS구분
  snsId: string; //	SNS토큰
  memInfoArr: MemberInfo[];
  snsToken: string;
  homepageGrade: string;
  homepageGradeName: string; //홈페이지 등급이름 (Classic, Premiere)
}

export interface UserIdFind {
  guestName: string; //	고객명
  hpNo: string; //	휴대폰번호
}

export interface PayOrderItemDto {
  id: number;
  regDate: Date;
  // 거래등록시 우리쪽에서 채우는 값
  mallId: string;
  payMethodTypeCode: string;
  currency: string;
  amount: number;
  clientTypeCode: string;
  returnUrl: string;
  shopOrderNo: string;
  deviceTypeCode: string;
  goodsName: string;

  // 거래등록 요청 후 PG에서 주는 Id
  authorizationId: string;
  // 거래승인 요청시에 우리가 설정하는 값
  shopTransactionId: string;

  // 거래승인 요청 후에 PG사에서 설정하는 값
  transactionDate: string;

  statusCode: string;
  statusMessage: string;
}

export interface PayOrderInfo {
  goodsName: string;
}

export interface PayMakeRequestDto {
  payMethodTypeCode: string;
  amount: number;
  deviceTypeCode: string;
  orderInfo: PayOrderInfo;
}

export interface PayMakeResponseDto {
  resCd: string;
  resMsg: string;
  authPageUrl: string;
}

export interface PayRefreshRequestDto {
  id: string;
}

export interface BV_OptionItemGeneral<T> {
  value: T;
  text: string;
}

// BootstrapVue component options list로 자주 쓰이는 모델
export type BV_OptionItem = BV_OptionItemGeneral<string>;
export type BV_OptionItemNumber = BV_OptionItemGeneral<number>;

// PMS API 응답 공통적인 부분
export interface PMSResDto {
  resultCode: string;
  msg: string;
}

// C001 로그인
export interface PMSResGuestLogin extends PMSResDto {
  obj: GuestUser;
}

// C001 로그인
export interface PMSResLogin extends PMSResDto {
  obj: User;
}

export interface PinxToken {
  pinxToken: string;
}

//Repo 공통

export interface RepoPage {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IBoardArticle {
  chgDate!: string;
  id!: number;
  memo!: string;
  readCnt!: number;
  regCompanyNo!: string;
  regDate!: string;
  regName!: string;
  title!: string;
  subtitle1!: string;
  subtitle2!: string;
  subtitle3!: string;
  subtitle4!: string;
  subtitle5!: string;
  link!: string;
  isHead!: boolean;
  startDate!: string;
  endDate!: string;
  sortIdx!: number;
  isView!: boolean;
  attachFiles!: BoardAttachFile[];
}
// 게시판 글
export class BoardArticle extends IBoardArticle {
  chgDate = '';
  id = 0;
  memo = '';
  readCnt = 0;
  regCompanyNo = '';
  regDate = '';
  regName = '';
  title = '';
  subtitle1 = '';
  subtitle2 = '';
  subtitle3 = '';
  subtitle4 = '';
  subtitle5 = '';
  link = '';
  isHead = false;
  startDate = '';
  endDate = '';
  sortIdx = 0;
  isView = false;
  attachFiles: BoardAttachFile[] = [];

  getFirstAttachFileUrl(isImage = true) {
    const found = this.getFiles(isImage).find(x => x.isImage === isImage);
    if (found) return `${found.urlPath}${found.fileName}`;
    return null;
  }

  getFiles(isImage = true) {
    if (this.attachFiles && this.attachFiles.length > 0) {
      return this.attachFiles.filter(x => x.isImage === isImage);
    }
    return [];
  }

  constructor(other: any) {
    super();
    Object.assign(this, other);
  }
}

export interface BoardArticleResponse {
  _embedded: {
    boardArticles: IBoardArticle[];
  };
  page: RepoPage;
}

// 관리자 관련

export interface AdminLoginDto {
  username: '';
  password: '';
}

export interface AdminUser {
  token: string;
  refreshToken: string;
  type: string;
  id: number;
  email: string;
  name: string;
  roles: string[];
}

export interface BoardInfo {
  // 게시판 제목
  boardName: string;

  // 리스팅 URL (파라메터 없음)
  listURL: string;

  // 쓰기 URL
  wirteURL: string;

  // 읽기 URL (/1 과 같이 BoardArticle id 첨부하는 형식)
  readURL: string;

  // 카테고리사용
  isCategoryUse: boolean;

  // 카테고리갯수
  cntCategory: number;

  // 첨부파일사용
  isAttatchFileUse: boolean;

  // 이미지첨부사용
  isImageFileUse: boolean;

  // 게시판 타입에 따라 BoardArticle 에 사용할 필드를 설정한다
  // BOARD = title, memo(본문), 이미지첨부, 파일첨부
  //   :뉴스룸, 공지사항
  // TITLE_PHOTO =  title, subtitle1, subtitle2, 이미지 첨부, link
  //   :메인슬라이더, 레스토랑, 레스토랑
  // TITLE_PHOTO_TIME =  title, subtitle1, subtitle2, 이미지 첨부, link, time
  //   :레스토랑, 스페셜오퍼
  // PHOTO_ONLY = title, subtitle, memo 상관 없이 첨부 이미지 로만 사용하는 것
  //   :포토뷰
  type: string;
}

export interface BoardAttachFile {
  id: number;
  // 실제파일명
  realFileName: string;
  // 저장파일명
  fileName: string;
  // 확장자
  fileExt: string;
  // 파일크기
  fileSize: number;
  // 웹 접근 경로
  urlPath: string;
  // 파일위치
  filePath: string;
  // 이미지 파일인지
  isImage: boolean;
  //이미지 파일일 경우 썸네일 Base64 인코딩된 데이터
  thumbnailPic: string;
  // 커스텀 정렬 순서
  sortIdx: number;
  // 노출여부
  isView: boolean;
}

export interface FormUploadFiles {
  uploading: any[]; // (사진) 파일 업로드 (아직 업로드 안된 것)
  uploadingPic: any[] | undefined; // base64 타입의 썸네일 이미지
  uploadedToDelete: BoardAttachFile[]; // (사진) 이미 업로드된 파일들 중 삭제 되어야 할 것들 (BoardAttachFile)
  uploaded: BoardAttachFile[]; // (사진) 이미 업로드된 파일들 (타입 - BoardAttachFile)
}

export interface BoardAttachFilesResponse {
  _embedded: {
    boardAttachFiles: BoardAttachFile[];
  };
}
