<template>
  <div class="container">
    <div>
      <h2>PERFECT JOURNEY</h2>
    </div>
    <div class="pDesc">
      <p v-html="$t('journey_desc')"></p>
    </div>
    <div>
      <SlideCarousels
        :item-total-count="items.length"
        :items="items"
        :breakpoints="breakpoints"
        :slide-number="3"
        fixed-height="500px"
        :gap="3"
      >
        <template v-slot:slides>
          <VueperSlide v-for="item in items" :key="item.id" :image="item.pSrc">
            <template #content>
              <div class="top">
                <PerfectJourneyItem :item="item" />
              </div>
            </template>
          </VueperSlide>
        </template>
      </SlideCarousels>
    </div>
  </div>
</template>

<script>
import SlideCarousels from '@/components/SlideCarousels';
import PerfectJourneyItem from '@/pages/MainPage/Components/PerfectJourneyItem';

import {VueperSlide} from 'vueperslides';
export default {
  name: 'PerfectJourney',
  components: {
    PerfectJourneyItem,
    SlideCarousels,
    VueperSlide,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      breakpoints: {
        992: {
          bullets: false,
          arrows: false,
          visibleSlides: 1,
          touchable: false,
          disableArrowsOnEdges: false,
        },
      },
    };
  },
};
</script>

<style scoped>
h2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: 8rem;
  text-align: center;
  color: #222222;
  font-family: 'Jeju Myeongjo', sans-serif;
  font-weight: 700;
}

p {
  padding-top: 1rem;
  height: auto;
  text-align: center;
  color: #222;
  font-family: Nanum Gothic, sans-serif;
  font-size: 1em !important;
}

.pDesc {
  display: block;
  height: auto;
  padding-bottom: 2rem;
  word-break: keep-all;
}
</style>

<i18n>
{
  "ko": {
    "journey_desc": "일반적인 리조트 개념을 뛰어넘어 새로운 휴식의 가치를 제공합니다.<br />제주 한라산 중턱의 푸른 자연이 주는 완전한 휴식, 그리고 Premium Resort에서 경험하는<br />특별한 서비스는 일상을 벗어난 완벽한 여정을 가능하게 합니다."
  }
,"en": {
  "journey_desc": "It sets a new value to resting beyond the ordinary concept of a resort.<br/>The perfect resting provided by the green nature in breast of Hallasan Mountain and the special<br/>services experienced in the premium resort enable a perfect journey outside the daily routine."
}
,"ja": {
  "journey_desc": "一般的なリゾートの概念を超えた新しいリラクゼーションの価値を贈ります。<br/>済州の漢拏山中腹の緑の自然が与える完全な休息、そしてプレミアムリゾートで経験する<br/>特別なサービスは、日常を離れた完璧な旅を可能なものにします。"
}
}
</i18n>
